import { useGetLocalizationText } from "_store/localization/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import "./CardOverlay.css";

const CardOverlay = ({ text, type, action, style }) => {
  const getLocalizationText = useGetLocalizationText();

  const isSmallScreen = useIsSmallScreen()

  const adaptStringToJsx = (text) => {
    let array = [];
    array = text.split("$");
    return (
      <div className="card-overlay-text-container">
        {array?.map?.((subString, index) => {
          if (index % 2 === 0) {
            return <span>{subString}</span>;
          } else {
            return (
              <span className="card-overlay-text-highlighted">{subString}</span>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div
      className={"card-overlay-wrapper " + (type ? type : "") + (isSmallScreen ? " smallScreen" : "")}
      style={{ ...style }}
    >
      <div className={"card-overlay-container" + (isSmallScreen ? " smallScreen" : "")}>
        {adaptStringToJsx(text)}
        {type === "confirmation" && (
          <div className="card-overlay-btn-container">
            <div
              className="card-overlay-cancel-btn"
              onClick={() => action("no")}
            >
              {getLocalizationText("Global_Button_Cancel")}
            </div>
            <div
              className="card-overlay-confirm-btn"
              onClick={() => action("yes")}
            >
              {getLocalizationText("Global_Button_Confirm")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardOverlay;
