import { useRecoilState, useRecoilValue } from "recoil";
import { filterLimitSelector } from "_store/filter/filterLimit/selectors";
import { filterSelector } from "_store/filter/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";
import FilterSlider from "./FilterSlider";
import AdvancedFilter from "features/advancedFilter/AdvancedFilter";
import AdvancedFilterOverview from "features/advancedFilterOverview/AdvancedFilterOverview";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";

const FilterContainer = ({ onFilterChange, freez, onAdvancedFilterChange }) => {
  const getLocalizationText = useGetLocalizationText();

  const [filter, setFilter] = useRecoilState(filterSelector);
  const filterLimit = useRecoilValue(filterLimitSelector);

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen()
  return (
    <div
      className={
        freez ? "filter-grid-container freez" : "filter-grid-container"
      }
      style={{
        boxShadow: isSmallScreen || isMediumScreen
          ? "0px"
          : "0px 10px 20px rgba(119, 119, 118, 0.2)",
      }}
    >
      {!isSmallScreen && !isMediumScreen && (
        <AdvancedFilterOverview onFilterChange={onAdvancedFilterChange} />
      )}
      <FilterSlider
        title={getLocalizationText("Step1_Filter_Width")}
        min={filterLimit.minWidth}
        max={filterLimit.maxWidth}
        start={filter.minWidth}
        end={filter.maxWidth}
        onSliderChangeHandler={(value) => {
          setFilter({ ...filter, minWidth: value[0], maxWidth: value[1] });
          return onFilterChange({
            ...filter,
            minWidth: value[0],
            maxWidth: value[1],
          });
        }}
      />
      <FilterSlider
        title={getLocalizationText("Step1_Filter_Depth")}
        min={filterLimit.minDepth}
        max={filterLimit.maxDepth}
        start={filter.minDepth}
        end={filter.maxDepth}
        onSliderChangeHandler={(value) => {
          setFilter({ ...filter, minDepth: value[0], maxDepth: value[1] });
          return onFilterChange({
            ...filter,
            minDepth: value[0],
            maxDepth: value[1],
          });
        }}
      />
      <FilterSlider
        title={getLocalizationText("Step1_Filter_Height")}
        min={filterLimit.minHeight}
        max={filterLimit.maxHeight}
        start={filter.minHeight}
        end={filter.maxHeight}
        onSliderChangeHandler={(value) => {
          setFilter({ ...filter, minHeight: value[0], maxHeight: value[1] });
          return onFilterChange({
            ...filter,
            minHeight: value[0],
            maxHeight: value[1],
          });
        }}
      />
      <FilterSlider
        title={getLocalizationText("Step1_Filter_Volume")}
        min={filterLimit.minVolume}
        max={filterLimit.maxVolume}
        start={filter.minVolume}
        end={filter.maxVolume}
        onSliderChangeHandler={(value) => {
          setFilter({ ...filter, minVolume: value[0], maxVolume: value[1] });
          return onFilterChange({
            ...filter,
            minVolume: value[0],
            maxVolume: value[1],
          });
        }}
      />
      <FilterSlider
        title={getLocalizationText("Step1_Filter_TempRange")}
        min={filterLimit.minTemperatureRange}
        max={filterLimit.maxTemperatureRange}
        start={filter.minTemperatureRange}
        end={filter.maxTemperatureRange}
        type="temp"
        onSliderChangeHandler={(value) => {
          setFilter({
            ...filter,
            minTemperatureRange: value[0],
            maxTemperatureRange: value[1],
          });
          return onFilterChange({
            ...filter,
            minTemperatureRange: value[0],
            maxTemperatureRange: value[1],
          });
        }}
      />
      <AdvancedFilter freez={freez} onFilterChange={onAdvancedFilterChange} />
    </div>
  );
};

export default FilterContainer;
