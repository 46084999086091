import { useLayoutEffect, Fragment, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Section from "components/Section";
import Configuration from "features/product/Configuration";
import { useGetLocalizationText } from "_store/localization/selectors";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import { useRecoilState, useRecoilValue } from "recoil";
import { dimensionSelector } from "_store/products/dimensions/selectors";
import {
  getDimensins,
  selectDimension,
} from "_store/products/dimensions/service";
import { useActivePage } from "_store/page/selectors";
import useDidMountEffect from "__utilities/hooks/useDidMountEffect";

const DimensionsPage = () => {
  const getLocalizationText = useGetLocalizationText();
  const { currPage } = useActivePage();
  const refreshSummary = useRefreshSummary();
  const [loaded, setLoaded] = useState(true);

  const [{ isLoading, dimensions: sections }, dispatch] =
    useRecoilState(dimensionSelector);

  const summary = useRecoilValue(summarySelector);

  useLayoutEffect(() => {
    refreshSummary(currPage);
    getDimensins(dispatch);
  }, []);
  
  useDidMountEffect(() => {
    setLoaded(false);
  }, [sections]);

  const isPageLocked =
    summary?.steps?.find((s) => s.number === currPage)?.isLocked !== false
      ? true
      : false;

  const allDimensions = sections.flatMap((section) => section.dimensions);
  const currentlySelectedPriceAmount = allDimensions.find(
    (e) => e.isSelected === true
  )?.price?.amount;

  return (
    <div className="page-container">
      {isLoading && loaded ? (
        <div className="centred-container">
          <LoadingSpinner />
        </div>
      ) : (
        <Fragment>
          <div className="page-header-title">
            {getLocalizationText("Step2_Subtitle")}
          </div>
          {sections &&
            sections.map((section) => (
              <Section
                key={`${section.id}-${section.title}`}
                title={section.title}
                description={section.description}
                type="column"
              >
                {section.dimensions.map((element) => {
                  return (
                    <Configuration
                      key={`${section.id}-${element.id}-${element.title}`}
                      id={element.id}
                      designator={element.designator}
                      title={element.title}
                      price={element.price}
                      image={element.imageUrl}
                      details={element.details}
                      isSelected={element.isSelected}
                      currentlySelectedPriceAmount={
                        currentlySelectedPriceAmount
                      }
                      userConfirmationRequired={
                        element.userConfirmationRequired
                      }
                      onClickHandler={(id) => {
                        id && selectDimension({ id }, dispatch);
                      }}
                      isClickable={!isLoading && !isPageLocked}
                    />
                  );
                })}
              </Section>
            ))}
        </Fragment>
      )}
    </div>
  );
};

export default DimensionsPage;
