import EstimateOverviewButton from "components/Buttons/EstimateOverviewButton/EstimateOverviewButton";
import CardSeparator from "components/CardSeparator/CardSeparator";
import Counter from "components/Counter/Counter";
import CardOverlay from "features/product/disableReason/CardOverlay";
import { ReturnCells } from "features/selection/BioCode/BioCode";
import { React, useState } from "react";
import { useSetRecoilState } from "recoil";
import { setConfigurationId } from "_config/session";
import { configurationsSelector } from "_store/configurations/selectors";
import {
  deleteConfiguration,
  editConfiguration,
  newConfiguration,
  setConfigurationQuantity,
} from "_store/configurations/service";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { bioCodeFormater } from "_store/summary/selectors";
import { priceFormater } from "__utilities/functions/Public";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { chooseRangePage, summaryPage } from "__utilities/PageStepEnum";
import EstimateOverviewCard from "./EstimateOverviewCard";
import "./EstimateOverviewElement.css";

export default function EstimateOverviewElement({
  children,
  configuration,
  imageUrl,
  refreshList,
  isLocked,
  type,
  readOnlyMode,
}) {
  const [isPopUpShown, setIsPopUpShown] = useState(false);
  const getLocalizationText = useGetLocalizationText();
  const { setPage, prevPage } = useActivePage();

  const regionObj = useGetSelectedRegionObject();

  const setConfiguration = useSetRecoilState(configurationsSelector);

  const isSmallScreen = useIsSmallScreen();

  return (
    <EstimateOverviewCard>
      {type === "create" ? (
        <>
          <div className="estimate-overview-configuration-create-container">
            <div className="estimate-overview-configuration-create-inner-wrapper">
              <EstimateOverviewButton
                onClick={() => {
                  newConfiguration(() => {
                    setPage(chooseRangePage);
                  });
                }}
              >
                +
              </EstimateOverviewButton>
              <span className="estimate-overview-configuration-create-text">
                {children}
              </span>
            </div>
          </div>
        </>
      ) : (
        <div
          className={
            "estimate-overview-element-container" +
            (isSmallScreen ? " smallScreen" : "")
          }
        >
          {isPopUpShown ? (
            <CardOverlay
              style={{ height: "130px" }}
              text={getLocalizationText("EstimateOverview_ConfirmDelete")}
              type={"confirmation"}
              action={(result) => {
                if (result === "yes") {
                  deleteConfiguration(configuration.id, refreshList);
                }
                setTimeout(() => {
                  setIsPopUpShown(false);
                }, 10);
              }}
            />
          ) : (
            <>
              <div className="estimate-overview-element-content-container-wrapper">
                {!readOnlyMode && (
                  <Counter
                    rotated={isSmallScreen}
                    value={configuration?.quantity}
                    minValue={1}
                    maxValue={null}
                    onChange={(value) => {
                      setConfigurationQuantity(configuration?.id, value, () => {
                        setConfiguration(null); // clearing configurations
                        refreshList();
                      });
                    }}
                    isSelected={true}
                  />
                )}
                <div
                  className={
                    "estimate-overview-element-content-container" +
                    (isSmallScreen ? " smallScreen" : "")
                  }
                >
                  <img
                    className={
                      "estimate-overview-element-image" +
                      (isSmallScreen ? " smallScreen" : "")
                    }
                    src={imageUrl}
                    alt=""
                  />
                  <div className="estimate-overview-element-info-container">
                    <div className="estimate-overview-element-info-title">
                      {configuration.productName}
                    </div>
                    <div className="estimate-overview-element-info-price">
                      {configuration?.quantity}
                      {" x "}
                      {priceFormater(
                        configuration.price.amount,
                        configuration.totalPrice.currencyCode,
                        regionObj?.localizationISOCode,
                        regionObj?.currencyPosition
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isSmallScreen && <CardSeparator />}
              <div
                className={
                  "estimate-overview-element-code-container" +
                  (isSmallScreen ? " smallScreen" : "")
                }
              >
                <div className="estimate-overview-element-code-title">
                  {getLocalizationText("EstimateOverview_BioCode_Title").replace("[#0#]", configuration.productName)}
                </div>
                {ReturnCells(bioCodeFormater(configuration.bioCode))}
              </div>
            </>
          )}
        </div>
      )}

      {readOnlyMode ? (
        <div
          className={
            "estimate-overview-element-button-container" +
            (isSmallScreen ? " smallScreen" : "")
          }
        >
          <EstimateOverviewButton
            onClick={() => {
              setConfigurationId(configuration.id);
              setPage(summaryPage);
            }}
          >
            <span className="estimate-overview-element-button-view-icon"></span>
          </EstimateOverviewButton>
        </div>
      ) : (
        <div
          className={
            "estimate-overview-element-button-container" +
            (isSmallScreen ? " smallScreen" : "")
          }
          style={{
            opacity: isPopUpShown ? 0 : 1,
            pointerEvents: isPopUpShown ? "none" : "",
          }}
        >
          <EstimateOverviewButton
            style={{ width: isSmallScreen ? "100%" : "" }}
            toolTipText={getLocalizationText("EstimateOverview_Button_Remove")}
            hide={type === "create"}
            onClick={() => {
              setIsPopUpShown(true);
            }}
          >
            <span className="estimate-overview-configuration-button-delete"></span>
          </EstimateOverviewButton>
          <EstimateOverviewButton
            style={{ width: isSmallScreen ? "100%" : "" }}
            toolTipText={getLocalizationText("EstimateOverview_Button_Edit")}
            hide={type === "create"}
            onClick={() => {
              editConfiguration(configuration.id, () => {
                setPage(summaryPage);
              });
            }}
          >
            <span className="estimate-overview-configuration-button-edit"></span>
          </EstimateOverviewButton>
        </div>
      )}
    </EstimateOverviewCard>
  );
}
