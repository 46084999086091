import React from "react";
import "./InputField.css";

export default function InputField({ label, onChange, isInvalid, invalidText, value, disabled, style, inputStyle }) {
  return (
    <div style={{width: "100%", pointerEvents: disabled ? "none" : "all", ...style}}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="input-field-label">{label}</div>
        <div
          className="input-field-mandatory-label"
          style={{ opacity: isInvalid ? 1 : 0 }}
        >
          {invalidText}
        </div>
      </div>
      <input
        style={{...inputStyle}}
        className={`input-field-value` + (isInvalid ? " invalid" : "")}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}
