import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isFirstStartUpHappened, urlSessionIdIsValid } from "_config/session";
import { filterLimitSelector } from "_store/filter/filterLimit/selectors";
import {
  advancedFilterSelector,
  filterSelector,
  useAdvancedFilter,
} from "_store/filter/selectors";
import { getFilterRequest } from "_store/filter/service";
import { getProductsRequest } from "_store/products/service";
import { useSetDefaultDescriptionValue } from "_store/selected/Description/selectors";
import { rangeSelector } from "_store/selected/selectors";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import "./ChooseRangePage.css";
import { useActivePage } from "_store/page/selectors";
import { chooseRegionPage } from "__utilities/PageStepEnum";
import RegionViewer from "features/regionViewer/RegionViewer";
import {
  sessionIsDistributorSelector,
  sortOptionsSelector,
  useSortOptionsLoader,
} from "_store/session/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { selectedSortOptionSelector } from "_store/products/cabinets/selectors";
import ChooseRangePageCabinetList from "./PageComponents/ChooseRangePageCabinetList";
import ChooseRangePageFilterContainer from "./PageComponents/ChooseRangePageFilterContainer";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";
import useFilterContainerWrapper from "./hooks/useFilterContainerWrapper";

const ChooseRangePage = ({ configurateSelected, refreshItems }) => {
  const setRange = useSetRecoilState(rangeSelector);

  const setFilter = useSetRecoilState(filterSelector);
  const setFilterLimit = useSetRecoilState(filterLimitSelector);

  const resetDescription = useSetDefaultDescriptionValue();

  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const refreshSummary = useRefreshSummary();

  const summary = useRecoilValue(summarySelector);

  const { setPage } = useActivePage();

  const isDistributor = useRecoilValue(sessionIsDistributorSelector);

  const {
    setupAdvancedFilter,
    getCombinedFilterResult,
    refreshAdvancedFilter,
  } = useAdvancedFilter();

  const sliderFilter = useRecoilValue(filterSelector);
  const advancedFilter = useRecoilValue(advancedFilterSelector);

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const { loadSortOptions } = useSortOptionsLoader();

  const sortOptions = useRecoilValue(sortOptionsSelector);
  const [selectedSortedOption, setSelectedSortedOption] = useRecoilState(
    selectedSortOptionSelector
  );

  const disableSectionHeader = !(
    selectedSortedOption === "ProductRange" || selectedSortedOption === null
  );

  const onAdvancedFilterChange = (advancedFilterContent) => {
    const combinedFilter = getCombinedFilterResult(
      sliderFilter,
      advancedFilterContent,
      selectedSortedOption
    );
    getItems(combinedFilter, () => setupAdvancedFilter(combinedFilter));
  };

  const onSliderFilterChange = (newFilter) => {
    const combinedFilter = getCombinedFilterResult(
      newFilter,
      advancedFilter,
      selectedSortedOption
    );
    getItems(combinedFilter, () => setupAdvancedFilter(combinedFilter));
  };

  const onSortDropdownChange = (value) => {
    setSelectedSortedOption(value);
    const combinedFilter = getCombinedFilterResult(
      sliderFilter,
      advancedFilter,
      value
    );
    getItems(combinedFilter);
  };

  useEffect(() => {
    refreshItems.current = getItems;
    getFilterRequest().then((data) => {
      setFilter(data.data);
      setFilterLimit(data.data);
      refreshAdvancedFilter();
      getItems(data.data, () => setupAdvancedFilter(data.data));
      loadSortOptions();
      const firstStartUp = isFirstStartUpHappened();
      const validation = urlSessionIdIsValid();
      const isNewConfig = firstStartUp && !validation.isValid;
      !isNewConfig && refreshSummary(1);
    }).catch((error) => {
      console.log(error);
    });
    resetDescription();
  }, []);

  const getItems = (newFilter, dispatch = () => {}) => {
    setIsLoading(true);
    getProductsRequest(newFilter)
      .then((data) => {
        setSections(data.data);
        dispatch();
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch();
        console.log(error);
      });
  };

  const { largeScreenFilterContent, smallScreenFilterContent, isFilterOverlayShown } =
    useFilterContainerWrapper(
      onSliderFilterChange,
      isLoading,
      onAdvancedFilterChange
    );

  return (
    <div className="page-container">
      <div className="choose-page-region-dropdown-container">
        <RegionViewer
          isDisabled={summary?.steps?.[0]?.isLocked || isDistributor}
          onClick={() => setPage(chooseRegionPage)}
        />
      </div>
      <div
        className={`choose-page-region-grid-container ${
          isSmallScreen || isMediumScreen ? "mediumScreen" : ""
        }`}
      >
        {!isSmallScreen && isMediumScreen ? (
          <></>
        ) : (
          <ChooseRangePageFilterContainer
            isLoading={isLoading}
            onSliderFilterChange={onSliderFilterChange}
            onAdvancedFilterChange={onAdvancedFilterChange}
            selectedSortedOption={selectedSortedOption}
            sortOptions={sortOptions}
            onSortDropdownChange={onSortDropdownChange}
            largeScreenFilterContent={largeScreenFilterContent}
            smallScreenFilterContent={smallScreenFilterContent}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center ",
          }}
        >
          {isLoading && !isFilterOverlayShown ? (
            <LoadingSpinner />
          ) : (
            <ChooseRangePageCabinetList
              sections={sections}
              selectedSortedOption={selectedSortedOption}
              sortOptions={sortOptions}
              onSortDropdownChange={onSortDropdownChange}
              setRange={setRange}
              configurateSelected={configurateSelected}
              disableSectionHeader={disableSectionHeader}
              isLoading={isLoading}
              onSliderFilterChange={onSliderFilterChange}
              onAdvancedFilterChange={onAdvancedFilterChange}
              largeScreenFilterContent={largeScreenFilterContent}
              smallScreenFilterContent={smallScreenFilterContent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseRangePage;
