import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { acceptConfigurationRequest } from "_store/configurations/service";
import { informingTypeSelector } from "_store/informingType/selectors";
import { optionsSelector } from "_store/products/options/selectors";
import { getOptions } from "_store/products/options/service";
import { summarySelector } from "_store/summary/selectors";
import { useValidateAndSendUserData } from "_store/userData/selectors";
import { restartMainScrollBar } from "__utilities/functions/Public";
import { chooseRangePage, estimatePage, informingTypePage, optionsPage, summaryPage, userDataPage } from "__utilities/PageStepEnum";
import state from "./index";

export const activePageSelector = selector({
  key: "store.page.selector",
  get: ({ get }) => get(state).page,
  set: ({ get, set }, newValue) => {
    if (newValue < 0) return;
    set(state, {
      ...get(state),
      page: newValue,
    });
  },
});

export const useActivePage = () => {
  const [page, setPage] = useRecoilState(activePageSelector);

  return {
    currPage: page,
    prevPage: (prevStep) => {
      restartMainScrollBar();
      if (prevStep) {
        return setPage(prevStep.number);
      }
      setPage(page - 1);
    },
    nextPage: (nextStep) => {
      restartMainScrollBar();
      if (nextStep?.isSummary === true) {
        return setPage(summaryPage);
      }
      if (nextStep) {
        return setPage(nextStep.number);
      }
      setPage(page + 1);
    },
    setPage: (pageIndex) => {
      restartMainScrollBar();
      return setPage(pageIndex);
    },
  };
};

export const useGoToStep = () => {
  let { steps, isLoading, isValid } = useRecoilValue(summarySelector);
  const { currPage, prevPage, nextPage } = useActivePage();
  const setOptions = useSetRecoilState(optionsSelector);
  const validation = useValidateAndSendUserData();
  const { informingType } = useRecoilValue(informingTypeSelector);

  let currStep;

  if (currPage === summaryPage) {
    currStep = steps.find((s) => s.number === 10);
  } else {
    currStep = steps.find((s) => s.number === currPage);
  }

  let nextStep = steps.find(
    (s) => s.number > currPage && s.isAvailable === true
  );

  let reverseSteps = [...steps];
  let prevStep = reverseSteps
    .reverse()
    .find(
      (s) => s.number < currPage && s.isAvailable === true && s.number !== 10
    );

  const mainCheck = currStep?.isSummary
    ? isLoading || currStep?.isSummary !== true
    : isLoading ||
      (currStep?.isCompleted !== true && currPage > chooseRangePage) ||
      (isValid !== true && currStep?.isLastStep === true);

  return {
    goToNextStep: () => {
      if (mainCheck)
        if (currPage < estimatePage)
          return; /* if (currPage < 101) added because currPage dose not exits after 100  */

      if (currStep?.isSummary === false) {
        nextPage(nextStep);
        if (currPage >= optionsPage && currStep?.isSummary === false) {
          getOptions(setOptions, nextStep?.number);
        }
      } else {
        if (currStep?.isSummary === true) {
          acceptConfigurationRequest()
            .then(() => {
              nextPage();
            })
            .catch((error) => console.log(error));
        } else if (currPage === informingTypePage && !informingType) {// preventing flickering if informing type not selected
          return;
        } else if (currPage === userDataPage) {
          validation(() => {
            nextPage();
          });
        } else {
          nextPage(null);
        }
      }
    },
    goToPrevStep: () => {
      if (isLoading) return;
      if (currPage > summaryPage) {
        prevPage();
      } else {
        if (prevStep?.number >= optionsPage && prevStep?.isSummary === false) {
          getOptions(setOptions, prevStep?.number);
          prevPage(prevStep);
        } else {
          prevPage(prevStep);
        }
      }
    },
    currStepIsCompleted: () =>
      !mainCheck ||
      currPage >
        summaryPage /* if (currPage < 101) added because currPage dose not exits after 100  */,
  };
};
