import { selector, useRecoilValue, useSetRecoilState } from "recoil";
import state from "./index";
import { getSortOptions } from "./service";

export const sessionRegionSelector = selector({
  key: "store.session.region",
  get: ({ get }) => get(state).region,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      region: newValue,
    }),
});

export const sessionRegionsSelector = selector({
  key: "store.session.regions",
  get: ({ get }) => get(state).regions,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      regions: newValue,
    }),
});

export const sessionIsDistributorSelector = selector({
  key: "store.session.isDistributor",
  get: ({ get }) => get(state).isDistributor,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      isDistributor: newValue,
    }),
});

export const sortOptionsSelector = selector({
  key: "store.session.sortOptions",
  get: ({ get }) => get(state).sortOptions,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      sortOptions: newValue,
    }),
});

export const useGetSelectedRegionObject = () => {
  const region = useRecoilValue(sessionRegionSelector);

  return region;
};

export const useSortOptionsLoader = () => {
  const setSortOptions = useSetRecoilState(sortOptionsSelector);

  const loadSortOptions = async () => {
    const { success, error, data } = await getSortOptions();

    if (success) {
      setSortOptions(data);
    } else {
      console.log(error);
    }
  };

  return { loadSortOptions: loadSortOptions };
};
