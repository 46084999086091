import { atom } from "recoil";

const state = atom({
  key: "store.products.dimensions.state",
  default: {
    isLoading: false,
    dimensions: [],
  },
});

export default state;
