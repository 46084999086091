import state from "./index";
import {
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { informingTypeSelector } from "_store/informingType/selectors";
import {
  sendContactMeRequest,
  sendEmailToRequesterRequest,
  sendQuotationRequest,
} from "./service";
import { validateEmail } from "__utilities/functions/Public";
import { userDataSendingStateSelector } from "./sendingState/selectors";
import { countriesSelector } from "_store/countries/selectors";

export const userDataSelector = selector({
  key: "store.userData.userData.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      ...newValue,
    }),
});

export const userDataFirstNameSelector = selector({
  key: "store.userData.firstName.selector",
  get: ({ get }) => get(state).firstName,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      firstName: { value: newValue },
    }),
});

export const userDataLastNameSelector = selector({
  key: "store.userData.lastName.selector",
  get: ({ get }) => get(state).lastName,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      lastName: { value: newValue },
    }),
});

export const userDataOrganizationSelector = selector({
  key: "store.userData.selector",
  get: ({ get }) => get(state).organization,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      organization: { value: newValue },
    }),
});

export const userDataEmailSelector = selector({
  key: "store.userData.email.selector",
  get: ({ get }) => get(state).email,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      email: { value: newValue },
    }),
});

export const userDataCountrySelector = selector({
  key: "store.userData.country.selector",
  get: ({ get }) => get(state).country,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      country: { value: newValue },
    }),
});

export const userDataPhoneSelector = selector({
  key: "store.userData.phone.selector",
  get: ({ get }) => get(state).phone,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      phone: { value: newValue },
    }),
});

export const userDataStreet1Selector = selector({
  key: "store.userData.street1.selector",
  get: ({ get }) => get(state).street1,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      street1: { value: newValue },
    }),
});

export const userDataStreet2Selector = selector({
  key: "store.userData.street2.selector",
  get: ({ get }) => get(state).street2,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      street2: { value: newValue },
    }),
});

export const userDataStreet3Selector = selector({
  key: "store.userData.street3.selector",
  get: ({ get }) => get(state).street3,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      street3: { value: newValue },
    }),
});

export const userDataZipSelector = selector({
  key: "store.userData.zip.selector",
  get: ({ get }) => get(state).zip,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      zip: { value: newValue },
    }),
});

export const userDataCitySelector = selector({
  key: "store.userData.city.selector",
  get: ({ get }) => get(state).city,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      city: { value: newValue },
    }),
});
export const userReferenceSelector = selector({
  key: "store.userData.reference.selector",
  get: ({ get }) => get(state).reference,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      reference: { value: newValue },
    }),
});

export const useRefreshUserDataState = () => {
  const set = useSetRecoilState(userDataSelector);

  return () => {
    set({
      firstName: { value: "", valid: true },
      lastName: { value: "", valid: true },
      organization: { value: "", valid: true },
      email: { value: "", valid: true },
      country: { value: "", valid: true },
      phone: { value: "", valid: true },
      street1: { value: "", valid: true },
      street2: { value: "", valid: true },
      street3: { value: "", valid: true },
      zip: { value: "", valid: true },
      city: { value: "", valid: true },
      reference: { value: "", valid: true },
    });
  };
};

export const useValidateAndSendUserData = () => {
  const [data, setData] = useRecoilState(userDataSelector);
  const { informingType } = useRecoilValue(informingTypeSelector);
  const setSendingState = useSetRecoilState(userDataSendingStateSelector);
  const selectedCountry = useRecoilValue(userDataCountrySelector)
  const countries = useRecoilValue(countriesSelector)

  const request =
    informingType === "createConfigurationID"
      ? sendEmailToRequesterRequest
      : informingType === "quotation"
      ? sendQuotationRequest
      : informingType === "contactMe" && sendContactMeRequest;
  return (dispatch) => {
    if (!informingType) return;

    let validation = validateUserData(data, informingType, setData);
    
    const countryCode = countries?.list?.filter(country => country.name === selectedCountry.value)?.[0]?.code

    validation.returnObj["countryCode"] = countryCode ?? "ROW" 

    if (validation.error === false) {
      setSendingState(true);
      request(validation.returnObj)
        .then(() => {
          setSendingState(false);
          dispatch();
        })
        .catch((error) => {
          setSendingState(false);
          console.log(error);
        });
    }
  };
};

const validateUserData = (data, informingType, dispatch) => {
  let error = false;
  let description = "Feel all (*) fields!!!";
  const { firstName } = data;
  const { lastName } = data;
  const { organization } = data;
  const { email } = data;
  const { country } = data;
  const { phone } = data;
  const { street1 } = data;
  const { street2 } = data;
  const { street3 } = data;
  const { zip } = data;
  const { city } = data;
  const { reference } = data;
  let returnObj = {};

  let validationObj = {
    firstName: { valid: true, ...firstName },
    lastName: { valid: true, ...lastName },
    organization: { valid: true, ...organization },
    country: { valid: true, ...country },
    email: { valid: true, ...email },
    street1: { valid: true, ...street1 },
    zip: { valid: true, ...zip },
    city: { valid: true, ...city },
    phone: { valid: true, ...phone },
  };
  if (!firstName.value) {
    validationObj.firstName.valid = false;
    error = true;
  }
  if (!lastName.value) {
    validationObj.lastName.valid = false;
    error = true;
  }
  if (!organization.value) {
    validationObj.organization.valid = false;
    error = true;
  }
  if (!country.value) {
    validationObj.country.valid = false;
    error = true;
  }
  if (!email.value || !validateEmail(email.value)) {
    validationObj.email.valid = false;
    error = true;
  }

  if (informingType === "createConfigurationID") {
    const obj = { ...data, ...validationObj };
    dispatch(obj);

    returnObj = {
      FirstName: firstName.value,
      LastName: lastName.value,
      Organization: organization.value,
      CountryName: country.value,
      Email: email.value,
      PhoneNumber: phone.value,
      Reference: reference.value
    };
  }
  if (informingType === "quotation") {
    if (!street1.value) {
      validationObj.street1.valid = false;
      error = true;
    }
    if (!zip.value) {
      validationObj.zip.valid = false;
      error = true;
    }
    if (!city.value) {
      validationObj.city.valid = false;
      error = true;
    }

    const obj = { ...data, ...validationObj };
    dispatch(obj);

    returnObj = {
      FirstName: firstName.value,
      LastName: lastName.value,
      Organization: organization.value,
      CountryName: country.value,
      Email: email.value,
      AddressLine1: street1.value,
      AddressLine2: street2.value,
      AddressLine3: street3.value,
      ZIPCode: zip.value,
      City: city.value,
      PhoneNumber: phone.value,
      Reference: reference.value
    };
  }
  if (informingType === "contactMe") {
    if (!phone.value) {
      validationObj.phone.valid = false;
      error = true;
    }

    const obj = { ...data, ...validationObj };
    dispatch(obj);
    returnObj = {
      FirstName: firstName.value,
      LastName: lastName.value,
      Organization: organization.value,
      CountryName: country.value,
      Email: email.value,
      PhoneNumber: phone.value,
      Reference: reference.value
    };
  }

  if (!validateEmail(email.value)) {
    error = true;
    description = "Bad email format!!!";
  }

  return { returnObj, error, description };
};
