import React, { useState } from "react";
import FilterContainer from "../../../components/FilterContainer";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useGetLocalizationText } from "_store/localization/selectors";

export default function useFilterContainerWrapper(
  onFilterChange,
  freez,
  onAdvancedFilterChange
) {
  const [showOverlay, setShowOverlay] = useState(false);
  const isSmallScreen = useIsSmallScreen();

  const getLocalizationText = useGetLocalizationText();

  const filterContent = () => (
    <FilterContainer
      freez={freez}
      onFilterChange={onFilterChange}
      onAdvancedFilterChange={onAdvancedFilterChange}
    />
  );

  const largeScreenFilterContent = () => (
    <div
      style={{
        width: "100%",
        paddingRight: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        {filterContent()}
      </div>
    </div>
  );

  const smallScreenFilterContent = () => (
    <>
      <div
        style={{
          border: "1px solid var(--primaryGreen)",
          borderRadius: isSmallScreen ? "5px" : "10px",
          padding: "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
          cursor: "pointer"
        }}
        onClick={() => setShowOverlay(true)}
      >
        {getLocalizationText("Global_Button_Filter")}
        <img className="filterIcon"/>
      </div>
      {showOverlay && (
        <div
          style={{
            position: "fixed",

            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "var(--primarySuperLightGray)",
            zIndex: 1000,
            
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              overflow: "auto",
              margin: "10px",
            }}
          >
            <div
              style={{ width: "95%", display: "flex", justifyContent: "end" }}
            >
              <div
                onClick={() => setShowOverlay(false)}
                className="popup-xicon"
              ></div>
            </div>
            <div style={{ padding: "5px", width: "95%" }}>{filterContent()}</div>
          </div>
        </div>
      )}
    </>
  );

  return { largeScreenFilterContent, smallScreenFilterContent, isFilterOverlayShown:showOverlay };
}
