import { selector } from "recoil";
import state from "./index";

export const compartmentSelector = selector({
  key: "store.selected.compartment.selector",
  get: ({ get }) => get(state).compartment,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      compartment: newValue,
    }),
});