import { getUuid } from "__utilities/functions/Public";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useGetLocalizationText } from "_store/localization/selectors";
import { setProductRequest } from "_store/selected/service";
import Section from "components/Section";
import Product from "features/product/Product";
import SortOptionsDropdown from "features/sortOptionsDropdown/SortOptionsDropdown";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";
import AdvancedFilterOverview from "features/advancedFilterOverview/AdvancedFilterOverview";

export default function ChooseRangePageCabinetList({
  sections,
  selectedSortedOption,
  sortOptions,
  onSortDropdownChange,
  setRange,
  configurateSelected,
  disableSectionHeader,
  smallScreenFilterContent,
  onAdvancedFilterChange,
}) {
  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();
  const getLocalizationText = useGetLocalizationText();

  return (
    <div style={{ width: "100%" }}>
      {!isSmallScreen && (
        <div
          className="sort-options-dropdown-wrapper"
          style={{ justifyContent: isMediumScreen ? "space-between" : "end" }}
        >
          {isMediumScreen && smallScreenFilterContent()}
          <div>
            <span style={{ marginRight: "10px" }}>
              {getLocalizationText("Step1_SortBy")}
            </span>
            <SortOptionsDropdown
              selectedValue={selectedSortedOption}
              options={sortOptions}
              onChange={onSortDropdownChange}
            />
          </div>
        </div>
      )}
      {(isSmallScreen || isMediumScreen) && (
        <AdvancedFilterOverview
          onFilterChange={onAdvancedFilterChange}
          styles={{
            padding: "10px",
            display: "flex",
            gap: "5px",
            flexWrap: "wrap",
          }}
        />
      )}
      <div className="choose-page-section-container">
        {(sections?.length == 0) && <div style={{width: "100%", textAlign: "center", marginTop: "10vh", color:"var(--primaryGray)", fontSize: "18px"}}>{getLocalizationText("Step1_Filter_NoResult")}</div>}
        {sections.map((section) => (
          <Section
            key={getUuid() /*`${section.title}-${section.description}`*/}
            title={section.title}
            description={section.description}
            type="colum"
            headerDisabled={disableSectionHeader}
          >
            <div className="items-grid-container">
              {section.cabinets.map((product) => (
                <Product
                  key={
                    getUuid() /*`${section.title}-${section.description}-${product.id}-${product.name}`*/
                  }
                  id={product.id}
                  name={product.name}
                  image={product.imageUrl}
                  details={product.details}
                  price={product.price}
                  userConfirmationRequired={product.userConfirmationRequired}
                  readMoreUrl={product.readMoreUrl}
                  configurateSelected={() => {
                    setRange(product.name);
                    setProductRequest(product.id)
                      .then(() => {
                        configurateSelected();
                      })
                      .catch((error) => console.log(error));
                  }}
                />
              ))}
            </div>
          </Section>
        ))}
      </div>
    </div>
  );
}
