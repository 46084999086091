import { chooseRangePage } from "__utilities/PageStepEnum";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { discardConfiguration } from "_store/configurations/service";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import CustomButton from "components/Buttons/CustomButton";
import Popup from "components/Popup/Popup";
import React from "react";

export default function DiscardPopup({ onClose, dispatch }) {
  const getLocalizationText = useGetLocalizationText();
  const { setPage } = useActivePage();
  const isSmallScreen = useIsSmallScreen();

  const onDiscard = () => {
    discardConfiguration((success) => {
      if (success) {
        if (dispatch) {
          dispatch()
        } else {
          setPage(chooseRangePage);
        }
       
        onClose();
      } else {
        console.log("Error on discard!!!")
      }
    });
  };

  return (
    <Popup
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        alignItems: "self-start",
        justifyContent: "start",
        padding: "10px",
        background: "var(--primarySuperLightGray)",
        overflow: "auto",
        overflowX: "hidden",
        maxHeight: "90%",
      }}
      onClose={onClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px",
        }}
      >
        <div style={{ marginBottom: "25px", fontSize: "24px", fontWeight: "400", "line-height": "30px", "letter-spacing": "0em", textAlign: "center" }}>
          {getLocalizationText("Discard_UserConfirmation_Title")}
        </div>
        <div style={{ marginBottom: "40px", fontSize: "15px", fontWeight: "300", "line-height": "30px", "letter-spacing": "0.03em", textAlign: "center" }}>
          {getLocalizationText("Discard_UserConfirmation_Text")}
        </div>

        <div style={{ display: "flex", gap: "10px" , flexDirection: isSmallScreen ? "column-reverse" : "row" }}>
          <CustomButton
            text={getLocalizationText("Global_Button_Cancel")}
            type="previous"
            onClick={onClose}
            style={{ width: "200px" }}
          />
          <CustomButton
            text={getLocalizationText("Global_Button_Discard")}
            type="next"
            onClick={onDiscard}
            style={{ width: "200px" }}
          />
        </div>
      </div>
    </Popup>
  );
}
