import {
  chooseRangePage,
  chooseRegionPage,
  confirmationPage,
  dimensionsPage,
  energyTypePage,
  estimatePage,
  informingTypePage,
  materialPage,
  optionsPage,
  summaryPage,
  userDataPage,
} from "__utilities/PageStepEnum";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { getConfigurationId, getReadOnlyModeStatus } from "_config/session";
import { configurationsSelector } from "_store/configurations/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useGoToStep } from "_store/page/selectors";
import { rangeSelector } from "_store/selected/selectors";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import PopupButton from "components/Buttons/ShowModelButton/PopupButton";
import DeletePopup from "features/deletePopup/DeletePopup";
import DeletePopupOpener from "features/deletePopup/DeletePopupOpener";
import DiscardPopup from "features/discardPopup/DiscardPopup";
import InvalidBiocodePopup from "features/invalidBiocodePopup/InvalidBiocodePopup";
import DescriptionWidget from "features/product/DescriptionWidget";
import MultiStepProgressBar from "features/progress/MultiStepProgressBar/MultiStepProgressBar";
import CompartmentIndicatorContainer from "features/selection/CompartmentIndicator/CompartmentIndicatorContainer";
import AboutRangePopup from "features/smallScreenPopups/AboutRangePopup";
import YourConfigurationPopup from "features/smallScreenPopups/YourConfigurationPopup";
import { Fragment, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import ChooseRangePage from "./ChooseRangePage/ChooseRangePage";
import ChooseRegionPage from "./ChooseRegionPage";
import DimensionsPage from "./DimensionsPage";
import MaterialPage from "./MaterialPage";
import EnergyTypePage from "./EnergyTypePage";
import OptionsPage from "./OptionsPage";
import ConfigurationsSummaryPage from "./afterConfigurationPages/ConfigurationsSummaryPage";
import ConfirmationPage from "./afterConfigurationPages/ConfirmationPage";
import EstimateOverviewPage from "./afterConfigurationPages/EstimateOverviewPage";
import ChooseInformingTypePage from "./afterConfigurationPages/ChooseInformingTypePage";
import CollectUserDataPage from "./afterConfigurationPages/CollectUserDataPage";
import SelectionStep from "features/selection";

export default function MainPage({
  canRender,
  showDiscardPopup,
  discardPopupDispatch,
  discardPopupOnCloseDispatch,
  showInvalidBiocodePopup,
  currPage,
  setPage,
  setDiscardPopup,
  setInvalidBiocodePopup
}) {
  const { goToNextStep, goToPrevStep, currStepIsCompleted } = useGoToStep();
  const range = useRecoilValue(rangeSelector);

  const getLocalizationText = useGetLocalizationText();
  const refreshItems = useRef(null);

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const [showAboutRangePopup, setAboutRangePopup] = useState(false);
  const [showYourConfigPopup, setYourConfigPopup] = useState(false);
  const [showDeletePopup, setDeletePopup] = useState(false);

  const refreshSummary = useRefreshSummary();
  const summary = useRecoilValue(summarySelector);
  const showCompartmentIndicator =
    summary?.cabinet?.comparment1?.fullSize?.spaceTotal > 0 &&
    currPage < summaryPage;

  const configurations = useRecoilValue(configurationsSelector);
  const configurationId = getConfigurationId();
  let showDeletePopupIndicator = configurations?.list?.length > 0;
  if (
    configurations?.list?.length === 1 &&
    configurations.list[0].id === configurationId
  ) {
    showDeletePopupIndicator = false;
  }

  return (
    <Fragment>
      {canRender && (
        <div
          className={
            "app" +
            (currPage === chooseRangePage ? " first-page-state" : "") +
            (currPage >= estimatePage && currPage < confirmationPage
              ? " overview"
              : "") +
            (currPage === confirmationPage ? " confirmation" : "")
          }
        >
          {currPage > chooseRangePage && currPage < estimatePage && (
            <div className="product">
              <Fragment>
                <div className="left-widget">
                  <DescriptionWidget />
                </div>
              </Fragment>
              {showCompartmentIndicator && (
                <div className="left-widget-compartment-indicator">
                  <CompartmentIndicatorContainer
                    disabled={currPage >= summaryPage ? true : false}
                  />
                </div>
              )}
            </div>
          )}
          <div
            className={
              "progress" +
              (currPage === confirmationPage ? " confirmation" : "")
            }
          >
            <>
              {(isSmallScreen || isMediumScreen) &&
                currPage > chooseRangePage &&
                currPage <= summaryPage && (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <PopupButton
                      text={`About ${range}`}
                      onClick={() => setAboutRangePopup(true)}
                    />
                    <PopupButton
                      text={"Your configuration"}
                      onClick={() => setYourConfigPopup(true)}
                    />
                  </div>
                )}
              {showAboutRangePopup && (
                <AboutRangePopup onClose={() => setAboutRangePopup(false)} />
              )}
              {showYourConfigPopup && (
                <YourConfigurationPopup
                  onClose={() => setYourConfigPopup(false)}
                  setDiscardPopup={setDiscardPopup}
                />
              )}
              {showDiscardPopup && (
                <DiscardPopup
                  onClose={() => {
                    setDiscardPopup(false);
                    if(discardPopupOnCloseDispatch) {
                      discardPopupOnCloseDispatch();
                    }
                  }}
                  dispatch={discardPopupDispatch}
                />
              )}
              {showDeletePopup && (
                <DeletePopup onClose={() => setDeletePopup(false)} />
              )}
              {showInvalidBiocodePopup && (
                <InvalidBiocodePopup
                  onClose={() => setInvalidBiocodePopup(false)}
                />
              )}
              {currPage < 101 && !getReadOnlyModeStatus() && (
                <Fragment>
                  {currPage <= chooseRangePage ? (
                    <Fragment>
                      <div className="header-title main">
                        {currPage === chooseRegionPage
                          ? getLocalizationText("Step0_Title")
                          : getLocalizationText("Step1_Title")}

                        {showDeletePopupIndicator && (
                          <div className="header-title-back-container">
                            <DeletePopupOpener
                              onClick={() => setDeletePopup(true)}
                            />
                          </div>
                        )}
                      </div>
                      {currPage !== chooseRegionPage && (
                        <div className="header-subtitle">
                          {getLocalizationText("Step1_Subtitle")}
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <div className="header-title">
                      {getLocalizationText(
                        "StepOverview_Title_Configure"
                      ).replace("[#0#]", "")}{" "}
                      <span className="header-title-range-span">{range}</span>
                    </div>
                  )}
                  <MultiStepProgressBar currentStep={currPage} />
                </Fragment>
              )}

              <div className="pages">
                {currPage === 0 && <ChooseRegionPage />}
                {currPage === chooseRangePage && (
                  <ChooseRangePage
                    configurateSelected={() => {
                      refreshSummary("", (steps) => {
                        let step = steps[0]; //skippable pages after cabinet selection
                        for (let i = 2; i <= steps.length; i++) {
                          if (steps[i].isAvailable) {
                            step = steps[i];
                            break;
                          }
                        }

                        setPage(step.number);
                      });
                    }}
                    refreshItems={refreshItems}
                  />
                )}
                {currPage === dimensionsPage && <DimensionsPage />}
                {currPage === materialPage && <MaterialPage />}
                {currPage === energyTypePage && <EnergyTypePage />}
                {currPage >= optionsPage && currPage < summaryPage && (
                  <OptionsPage />
                )}
                {currPage === summaryPage && <ConfigurationsSummaryPage />}
                {currPage === estimatePage && <EstimateOverviewPage />}
                {currPage === informingTypePage && <ChooseInformingTypePage />}
                {currPage === userDataPage && <CollectUserDataPage />}
                {currPage === confirmationPage && <ConfirmationPage />}
              </div>
            </>
          </div>
          {currPage > chooseRangePage && currPage !== confirmationPage && (
            <Fragment>
              <SelectionStep
                activePageState={currPage}
                nextBtnClickHandler={() => {
                  goToNextStep();
                }}
                prevBtnClickHandler={() => {
                  goToPrevStep();
                }}
                currStepIsCompleted={currStepIsCompleted()}
                setDiscardPopup={setDiscardPopup}
                setDeletePopup={setDeletePopup}
              />
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
}
