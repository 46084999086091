import { useRecoilValue } from "recoil";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { summarySelector } from "_store/summary/selectors";
import { priceFormater } from "__utilities/functions/Public";
import "./TotalCostWidget.css";

const TotalCostWidget = ({ title }) => {
  const summary = useRecoilValue(summarySelector);
  //const isLoading = summary.isLoading
  const totalPrice = summary.totalPrice;
  const regionObj = useGetSelectedRegionObject();
  
  return (
    <div className="total-cost-widget-container">
      <div className="total-cost-widget-title">{title}</div>
      <div className="total-cost-widget-value">
        {priceFormater(
          totalPrice?.amount,
          totalPrice?.currency,
          regionObj?.localizationISOCode,
          regionObj?.currencyPosition
        )}
      </div>
    </div>
  );
};
export default TotalCostWidget;
