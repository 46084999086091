import React, { useState } from "react";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { priceFormater } from "__utilities/functions/Public";
import "./ConfigSummaryItem.css";

export default function ConfigSummaryItem({
  id,
  image,
  type,
  quantity,
  title,
  price,
  currencyCode,
  chargableQuantity,
}) {
  const getLocalizationText = useGetLocalizationText();

  const regionObj = useGetSelectedRegionObject();

  const [isPopUpShown, setIsPopUpShown] = useState(false);

  return (
    <div
      className={
        "config-summary-item-container" + (isPopUpShown ? " overlaid" : "")
      }
      onMouseLeave={() => setIsPopUpShown(false)}
    >
      {isPopUpShown ? (
        <div className="config-summary-item-overlay">
          The BioUltra Ul570 model comes with 5 drawers. The price shown refers
          to your choice of adding 3 additional drawers.
        </div>
      ) : (
        <>
          <div className="config-summary-item-left-container">
            <img className="config-summary-item-img" src={image} alt="" />
            <div className="config-summary-item-info">
              <div className="config-summary-item-title">
                {quantity && type === "Numeric"
                  ? quantity.toString() + " x "
                  : ""}
                {title}
              </div>
              <div className="config-summary-item-price">
                {(chargableQuantity || chargableQuantity === 0) &&
                  getLocalizationText("Summary_Total_Title") + ": "}
                {priceFormater(
                  price * chargableQuantity,
                  currencyCode,
                  regionObj?.localizationISOCode,
                  regionObj?.currencyPosition
                )}
                {/* <div className="config-summary-item-notification-sign-container">
                  <div
                    className="config-summary-item-notification-sign"
                    onMouseOver={() => setIsPopUpShown(true)}
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="config-summary-item-code">{id}</div>
        </>
      )}
    </div>
  );
}
