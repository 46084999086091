import ApiClient from "../../_config/client";

export const getFilterRequest = () => ApiClient.get("/region/filter");

export const getAdvancedFilterRequest = (filter) =>
  ApiClient.post("/region/advancedFilter", filter);

export const getAdvancedFilter = async (filter) => {
  try {
    const { data } = await getAdvancedFilterRequest(filter);

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
};
