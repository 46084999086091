import RegionSelector from "features/regionSelector/RegionSelector";
import React, { useEffect, useState } from "react";
import "./ChooseRegionPage.css";
import CustomButton from "components/Buttons/CustomButton";
import { useActivePage, useGoToStep } from "_store/page/selectors";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import {
  sessionRegionSelector,
  sessionRegionsSelector,
} from "_store/session/selectors";
import { useRecoilState, useRecoilValue } from "recoil";
import { getBioCodeFromURL, getRegion, setRegion } from "_config/session";
import {
  createNewConfigurationFromBiocode,
  getConfigurationRegionRequest,
  setConfigurationRegionRequest,
  validateBiocode,
} from "_store/configurations/service";
import Popup from "components/Popup/Popup";
import { useGetLocalizationText } from "_store/localization/selectors";
import { removeFromURL } from "__utilities/functions/Public";
import InvalidBiocodePopup from "features/invalidBiocodePopup/InvalidBiocodePopup";

export default function ChooseRegionPage() {
  const { goToNextStep, currStepIsCompleted } = useGoToStep();
  const { currPage, setPage } = useActivePage();
  const [regionState, setRegionState] = useRecoilState(sessionRegionSelector);
  const regions = useRecoilValue(sessionRegionsSelector);
  const [showPopup, setShowPopup] = useState(false);
  const [showInvalidBiocodePopup, setShowInvalidBiocodePopup] = useState(false);

  const getLocalizationText = useGetLocalizationText();

  const summary = useRecoilValue(summarySelector);

  const refreshSummary = useRefreshSummary();

  useEffect(() => {
    const hasRegion = getRegion();
    if (hasRegion) {
      refreshSummary(currPage);
      getConfigurationRegionRequest()
        .then(({ data }) => {
          setRegionState(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const biocodeValidation = async (dispatch) => {
    const biocode = getBioCodeFromURL();

    if (biocode) {
      const isValid = await validateBiocode(biocode);
      if (isValid) {
        dispatch(biocode);
      } else {
        setShowInvalidBiocodePopup(true);
      }
    } else {
      dispatch(null)
    }
  };

  const createNewConfigFromBiocode = async (biocode) => {
    const success = await createNewConfigurationFromBiocode(biocode);
    if (success) {
      refreshSummary(2, (steps) => {
        const firstNotCompletedStep = steps.find(step => !step.isCompleted)
        
        if(firstNotCompletedStep.number <= 4) {
          setPage(firstNotCompletedStep.number)
        } else {
          const firstAvailableStep = steps.find(step => {
            if(step.number > 4) {
              return step.isAvailable
            }
          })

          if(firstAvailableStep) {
            setPage(firstAvailableStep.number)
          }
        }
      });
      removeFromURL(`?biocode=${biocode}`)
    }
  };

  const onStartConfiguration = () => {
    if (!regionState.code) return;
    const selectedRegion = regions.find(
      (item) => item.code === regionState.code
    );
    setRegionState(selectedRegion);

    refreshSummary(currPage);
    setRegion(regionState.code);

    setConfigurationRegionRequest()
      .then(() => {
        if (currStepIsCompleted) {
          biocodeValidation((biocode) => {
            if (biocode) {
              createNewConfigFromBiocode(biocode);
            } else {
              goToNextStep();
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const regionCheck = () => {
    const currRegion = getRegion();
    const showPopupCheck =
      summary?.steps?.[1]?.isCompleted && currRegion !== regionState?.code;

    if (showPopupCheck) {
      setShowPopup(true);
    } else {
      onStartConfiguration();
    }
  };

  const onPopupConfirm = () => {
    setShowPopup(false);
    onStartConfiguration();
  };

  const getPopupContent = () => (
    <Popup>
      <div className="choose-region-page-popup-container">
        <div className="choose-region-page-popup-text">
          {getLocalizationText("Step0_UserConfirmation_ChangeRegion")}
        </div>
        <div className="choose-region-page-popup-button-container">
          <div
            className="choose-region-page-popup-confirm-btn"
            onClick={() => setShowPopup(false)}
          >
            {getLocalizationText("Global_Button_Cancel")}
          </div>
          <div
            className="choose-region-page-popup-cancel-btn"
            onClick={() => onPopupConfirm()}
          >
            {getLocalizationText("Global_Button_Confirm")}
          </div>
        </div>
      </div>
    </Popup>
  );

  return (
    <div className="choose-region-page-container">
      {showPopup && getPopupContent()}
      {showInvalidBiocodePopup && <InvalidBiocodePopup onClose={() => setShowInvalidBiocodePopup(false)} />}
      <RegionSelector />
      <div className="choose-region-page-button-container">
        <CustomButton
          text={getLocalizationText("Global_Button_Start")}
          type="next"
          onClick={onStartConfiguration}
        />
      </div>
    </div>
  );
}
