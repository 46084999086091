import { useGetLocalizationText } from "_store/localization/selectors";
import React from "react";
import "./RegionViewer.css";
import { useRecoilValue } from "recoil";
import { sessionRegionSelector } from "_store/session/selectors";

export default function RegionViewer({ onClick, isDisabled }) {
  const regionState = useRecoilValue(sessionRegionSelector);
  const getLocalizationText = useGetLocalizationText();

  return (
    <div className="region-viewer-container">
      Region:{" "}
      <img className="region-viewer-image" alt="" src={regionState?.imageSrc} />
      <div
        className="region-viewer-link"
        onClick={isDisabled ? () => {} : onClick}
        style={{
          textDecoration: isDisabled ? "none" : "underline",
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
      >
        {regionState?.name} ({regionState?.currency})
      </div>
    </div>
  );
}
