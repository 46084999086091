import state from "./index";
import types from "_store/types";
import { selector } from "recoil";

export const energyTypeSelector = selector({
  key: "store.products.energyTypes.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, action) => {
    switch (action.type) {
      case types.started:
        return set(state, {
          ...get(state),
          isLoading: true,
        });
      case types.success:
        return set(state, {
          ...get(state),
          energyTypes: action.payload,
          isLoading: false,
        });
      case types.error:
        return set(state, {
          ...get(state),
          isLoading: false,
        });
      default:
        return set(state, ...get(state));
    }
  },
});
