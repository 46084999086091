import RemoveButton from "components/Buttons/RemoveButton";
import { Fragment, useRef, useState } from "react";
import Counter from "components/Counter/Counter";
import Switch from "components/Switch/Switch";
import CardOverlay from "./disableReason/CardOverlay";
import "./Option.css";
import CustomRadioButton from "components/Buttons/CustomRadioButton";
import {
  addSignToFormatedPrice,
  priceFormater,
} from "__utilities/functions/Public";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import CardSeparator from "components/CardSeparator/CardSeparator";

const Option = ({
  id,
  compartment,
  title,
  price,
  image,
  details,
  isSelected,
  quantity,
  minValue,
  maxValue,
  isAvailable,
  isDisabled,
  disableReason,
  isLocked,
  canDelete,
  optionType,
  size,
  type,
  currentlySelectedPriceAmount,
  onClickHandler,
  userConfirmationOnDeleteRequired,
  userConfirmationOnDeleteText,
  userConfirmationOnSelectRequired,
  userConfirmationOnSelectText,
  isMaxAmountReached,
  maxAmountReachedText
}) => {
  const [isPopUpShownOnPlusHover, setIsPopUpShownOnPlusHover] = useState(false);
  const [isPopUpShown, setIsPopUpShown] = useState(false);
  const [isConfirmationPopUpData, setConfirmationPopUpData] = useState({
    show: false,
    text: "",
    dispatch: () => {},
  });
  const setCountRef = useRef();
  const setInputRef = useRef();
  const regionObj = useGetSelectedRegionObject();
  const isSmallScreen = useIsSmallScreen();

  const onClickConfirmationPopupChack = (_quantity, type) => {
    // _quantity - (callback from counter), quantity - (current)

    if (userConfirmationOnDeleteRequired) {
      if (_quantity === 0 || !_quantity) {
        setConfirmationPopUpData({
          show: true,
          text: userConfirmationOnDeleteText ?? "",
          dispatch: () => onClickHandler(_quantity, type),
        });
      } else {
        onClickHandler(_quantity, type);
      }
    } else if (userConfirmationOnSelectRequired) {
      if (
        ((quantity === 0 && _quantity > 0) ||
          _quantity === null ||
          _quantity === undefined) &&
        !isSelected
      ) {
        setConfirmationPopUpData({
          show: true,
          text: userConfirmationOnSelectText ?? "",
          dispatch: () => onClickHandler(_quantity, type),
        });
      } else {
        onClickHandler(_quantity, type);
      }
    } else {
      onClickHandler(_quantity, type);
    }
  };
  
  const confirmationPopupHandler = (result) => {
    if (result === "yes") {
      isConfirmationPopUpData.dispatch();
    } else {
      setCountRef?.current?.(quantity);
      setInputRef?.current?.(quantity);
    }
    setTimeout(() => {
      setConfirmationPopUpData({ show: false, text: "", dispatch: () => {} });
    }, 10);
  };

  const optionLogic = () => {
    if (isAvailable === false) {
      if (canDelete === false) return;
      if (canDelete === true) {
        return <RemoveButton onClick={onClickConfirmationPopupChack} />;
      }
    } else if (isLocked) return;
    else if (isDisabled === true) {
      if (canDelete === false) return;
      if (canDelete === true && isSelected === true) {
        return <RemoveButton onClick={onClickConfirmationPopupChack} />;
      }
      return;
    } else if (optionType === "Numeric") {
      return (
        <Counter
          value={quantity}
          minValue={minValue}
          maxValue={maxValue}
          onChange={onClickConfirmationPopupChack}
          isSelected={isSelected}
          setCountRef={setCountRef}
          setInputRef={setInputRef}
          hasInput={true}
          delayAmount={650}
          onPlusButtonMouseOver={() => { isMaxAmountReached &&  setIsPopUpShownOnPlusHover(true) }}
        />
      );
    } else if (optionType === "YesNo") {
      return (
        <Switch onClick={onClickConfirmationPopupChack} checked={isSelected} />
      );
    } else if (optionType === "Group") {
      return;
    }
  };
  return (
    <div
      className="option-card-wrapper"
      onClick={
        optionType === "Group"
          ? () => onClickConfirmationPopupChack(null, "Group")
          : () => {}
      }
    >
      {optionType === "Group" && !isSmallScreen && <CustomRadioButton state={isSelected} />}
      <div
        className={`option-card-container ${isSelected && "active"} ${
          isDisabled && !isPopUpShown && "disabled"
        } ${isSmallScreen && " smallScreen"}`}
        // onMouseEnter={() => setIsPopUpShown(true)}
        onMouseLeave={() => {setIsPopUpShown(false); setIsPopUpShownOnPlusHover(false)}}
        onMouseOver={() => setIsPopUpShown(true)}
      >
        {disableReason && isPopUpShown && (
          <CardOverlay text={disableReason} type="Disable-Reason" />
        )}

        {isMaxAmountReached && isPopUpShownOnPlusHover && (
          <CardOverlay text={maxAmountReachedText} type="Disable-Reason" style={{zIndex: 100}}/>
        )}
  
        {isConfirmationPopUpData.show ? (
          <CardOverlay
            style={{ minHeight: "100px" }}
            text={isConfirmationPopUpData.text}
            type={"confirmation"}
            action={confirmationPopupHandler}
          />
        ) : (
          <Fragment>
            <div
              className={
                "option-card-left" + (isSmallScreen ? " smallScreen" : "")
              }
            >
              <div
                className={
                  "option-card-image-container" +
                  (isSmallScreen ? " smallScreen" : "")
                }
              >
                <img className="option-card-image" src={image} alt="" />
              </div>
              {isSmallScreen && <CardSeparator />}
              <div className="option-card-details-container">
                <div
                  className={`option-card-title ${
                    type === "configuration" && "configuration"
                  }`}
                >
                  {title}
                </div>
                {type !== "configuration" && (
                  <div className="option-card-text">{details}</div>
                )}
              </div>
            </div>
            {isSmallScreen && !isSelected && <CardSeparator />}
            <div
              style={{
                display: isSmallScreen ? "flex" : "",
                justifyContent: "space-between",
                alignItems: isSmallScreen ? "center" : ""
              }}
            >
              {optionLogic()}
              {!isLocked && !(isSelected && optionType === "Group") && (
                <>
                  {optionType === "Group" ? (
                    <div className={"option-card-price" + (isSmallScreen ? " smallScreen" : "")}>
                      {currentlySelectedPriceAmount ||
                      currentlySelectedPriceAmount === 0
                        ? addSignToFormatedPrice(
                            price.amount - currentlySelectedPriceAmount,
                            price.currencyCode,
                            regionObj?.localizationISOCode,
                            regionObj?.currencyPosition
                          )
                        : priceFormater(
                            price.amount,
                            price.currencyCode,
                            regionObj?.localizationISOCode,
                            regionObj?.currencyPosition
                          )}{" "}
                      {/* {price.currency} */}
                    </div>
                  ) : (
                    <>
                      <div className={"option-card-price" + (isSmallScreen ? " smallScreen" : "")}>
                        {/* DEFAULT */}
                        {priceFormater(
                          price.amount,
                          price.currency,
                          regionObj?.localizationISOCode,
                          regionObj?.currencyPosition
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
              {/* <div className="option-card-compartment">{compartment}</div> */}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Option;
