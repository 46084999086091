import { useState } from "react";
import { sessionRegionSelector, useGetSelectedRegionObject } from "_store/session/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";
import "./ContactSection.css";
import { useRecoilValue } from "recoil";

const ContactSection = ({style}) => {
  const [show, setShow] = useState(false);
  const regionObj = useRecoilValue(sessionRegionSelector) //useGetSelectedRegionObject();
  const getLocalizationText = useGetLocalizationText();

  return (
    <div className="contact-section-container" style={{...style}}>
      {!show && (
        <div
          onClick={() => setShow(true)}
          style={{ marginBottom: "10px", cursor: "pointer" }}
        >
          {getLocalizationText("Summary_NeedSupport_Title")}
        </div>
      )}
      {show && (
        <div className="contact-section-content-container">
          <div className="contact-section-information">
            <img className="contact-section-icon phone" alt="" />
            {regionObj?.needHelpPhone}
          </div>
          <div className="contact-section-information">
            <img className="contact-section-icon mail" alt="" />
            {regionObj?.needHelpEmail}
          </div>
          <div className="contact-section-information">
            <img className="contact-section-icon clock" alt="" />
            {regionObj?.needHelpWorkingHours}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactSection;
