import { setConfigurationId } from "_config/session";
import ApiClient from "../../_config/client";

export const newConfigurationRequest = () =>
  ApiClient.post("/configuration/new");

export const getConfigurationRegionRequest = () =>
  ApiClient.get("configuration/region");

export const setConfigurationRegionRequest = () =>
  ApiClient.put("/configuration/region");

export const getConfigurationsRequest = () => ApiClient.get("/configuration");

export const acceptConfigurationRequest = () =>
  ApiClient.post("/configuration/accept");

export const editConfigurationRequest = (configurationId) => {
  setConfigurationId(configurationId);
  return ApiClient.post(`configuration/edit`);
};

export const deleteConfigurationRequest = (configurationId) => {
  setConfigurationId(configurationId);
  return ApiClient.delete(`configuration`);
};

export const discardConfigurationRequest = () => {

  return ApiClient.post(`configuration/discard`);
}

export const setConfigurationQuantityRequest = (configurationId, quantity) => {
  setConfigurationId(configurationId);
  return ApiClient.put(`configuration/setQuantity?quantity=${quantity}`);
};

export const validateBiocodeRequest = (biocode) => {
  return ApiClient.post(`configuration/biocode/${biocode}/validate`);
};

export const createConfigurationFromBiocodeRequest = (biocode) => {
  return ApiClient.post(`configuration/biocode/${biocode}/new`);
};

export const setConfigurationQuantity = (
  configurationId,
  quantity,
  dispatch
) => {
  setConfigurationQuantityRequest(configurationId, quantity)
    .then(() => {
      dispatch();
    })
    .catch((error) => {
      dispatch();
      console.log(error);
    });
};

export const deleteConfiguration = (configurationId, dispatch) => {
  deleteConfigurationRequest(configurationId)
    .then(() => {
      dispatch(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editConfiguration = (configurationId, dispatch) => {
  editConfigurationRequest(configurationId)
    .then(() => {
      dispatch();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const discardConfiguration = (dispatch) => {
  discardConfigurationRequest()
    .then(() => {
      dispatch(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const newConfiguration = (dispatch) => {
  newConfigurationRequest()
    .then(({ data }) => {
      setConfigurationId(data.id);
      dispatch();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const validateBiocode = async (biocode) => {
  try {
   await validateBiocodeRequest(biocode)
   return true
  } catch (error){
    console.log(error);
    return false
  }
};

export const createNewConfigurationFromBiocode = async (biocode) => {
  try {
    const data = await createConfigurationFromBiocodeRequest(biocode)
    
    setConfigurationId(data.data.id);
    return true
   } catch (error){
     console.log(error);
     return false
   }
}