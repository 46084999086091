import RemoveButton from "components/Buttons/RemoveButton";
import Counter from "components/Counter/Counter";
import { useGetLocalizationText } from "_store/localization/selectors";
import "./OptionSummaryElement.css";

export default function OptionSummaryElement({
  id,
  name,
  image,
  price,
  value,
  isLocked,
  isDisabled,
  disableReason,
  isAvailable,
  canDelete,
  minValue,
  maxValue,
  type,
  quantity,
  occupiedSpace,
  chargableQuantity,
  onChange,
}) {
  const getLocalizationText = useGetLocalizationText();

  const optionLogic = () => {
    if (isAvailable === false) {
      if (canDelete === false) return;
      if (canDelete === true) {
        return <RemoveButton onClick={() => onChange()} />;
      }
    } else if (isLocked) return;
    else if (isDisabled === true) {
      if (canDelete === false) return;
      if (canDelete === true) {
        return <RemoveButton onClick={() => onChange()} />;
      }
    } else if (type === "Numeric") {
      return (
        <Counter
          type="-summary"
          value={value}
          minValue={minValue}
          maxValue={maxValue}
          onChange={onChange}
        />
      );
    } else if (type === "YesNo") {
      return <RemoveButton onClick={() => onChange()} />;
    }
  };
  return (
    <div
      title={disableReason}
      className={
        "summary-option-element-container" +
        (isDisabled || !isAvailable ? " disabled" : "")
      }
    >
      <img className="summary-option-element-image" src={image} alt="" />
      <div className="summary-option-element-info-container">
        <div className="summary-option-element-name">
          {type === "Numeric" &&
            chargableQuantity !== 0 &&
            chargableQuantity + " x "}
          {name}
        </div>
        {isLocked ? (
          <div className="summary-option-element-price">{getLocalizationText("Step5_Option_Included")}</div>
        ) : (
          <div className="summary-option-element-price">
            {type === "Numeric" && getLocalizationText("Summary_Total_Title")+":"}{" "}
            {/*price.amount!==0 && */ chargableQuantity * price.amount}{" "}
            {price.currency}
          </div>
        )}
      </div>
    </div>
  );
}
