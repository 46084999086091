import React from "react";
import "./EstimateOverviewButton.css";

export default function EstimateOverviewButton({ children, onClick, hide, toolTipText, style}) {
  return (
    <div
      style={{...style}}
      title={toolTipText}
      className={"estimate-overview-button-container" + (hide ? " hide" : "")}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
