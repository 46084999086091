import { red } from "@mui/material/colors";

const defautlIcon =
  "https://png.pngtree.com/png-clipart/20190516/original/pngtree-question-mark-vector-icon-png-image_4236972.jpg";

export default function AdvancedFilterElementImage({
  item: { isSelected, count, imageUrl = null, title },
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          border: isSelected
            ? "solid 3px var(--secondaryGreen)"
            : "solid 3px transparent",
          padding: "2px",
          borderRadius: "2px",
          height: "37px",
        }}
      >
        <img
          src={imageUrl ?? defautlIcon}
          alt=""
          style={{
            width: "37px",
            height: "37px",
          }}
          title={title}
        />
      </div>
      <span
        style={{
          color: "var(--activeBlack)",
          textAlign: "center",
        }}
      >
        {count}
      </span>
    </div>
  );
}
