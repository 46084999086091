import React from "react";
import "./RegionSelectorElement.css";

export default function RegionSelectorElement({
  name,
  image,
  currency,
  isSelected,
  onClick,
}) {
  return (
    <div className="region-selector-element-container-wrapper" onClick={onClick}>
      <div
        className={`${
          isSelected
            ? "region-selector-element-circle-selected"
            : "region-selector-element-circle-notSelected"
        }`}
      >
        {isSelected && <div className="region-selector-element-inner-circle" />}
      </div>
      <div
        className={`region-selector-element-container ${
          isSelected ? "selected" : ""
        }`}
      >
        <img className="region-selector-element-image" src={image} />
        <div className="region-selector-element-text-container">
          <div className="region-selector-element-name">{name}</div>
          <div className="region-selector-element-currency">{currency}</div>
        </div>
      </div>
    </div>
  );
}
