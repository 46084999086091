import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useGetLocalizationText } from "_store/localization/selectors";
import SortOptionsDropdown from "features/sortOptionsDropdown/SortOptionsDropdown";

export default function ChooseRangePageFilterContainer({
  onAdvancedFilterChange,
  selectedSortedOption,
  sortOptions,
  onSortDropdownChange,
  largeScreenFilterContent, 
  smallScreenFilterContent 
}) {
  const isSmallScreen = useIsSmallScreen();
  const getLocalizationText = useGetLocalizationText();

  return (
    <div
        className={`choose-page-filter-wrapper ${
          isSmallScreen ? "smallScreen" : ""
        }`}
      >
        {isSmallScreen ? smallScreenFilterContent() : largeScreenFilterContent()}
        {isSmallScreen && (
          <>
            <span style={{ minWidth: "fit-content" }}>
              {getLocalizationText("Step1_SortBy")}
            </span>
            <SortOptionsDropdown
              selectedValue={selectedSortedOption}
              options={sortOptions}
              onChange={onSortDropdownChange}
              styles={{
                width: "100%",
                marginRight: "10px",
                borderRadius: isSmallScreen ? "5px" : "10px",
              }}
            />
          </>
        )}
      </div>
  );
}
