import state from "./index";
import { selector, useRecoilState, useSetRecoilState } from "recoil";
import {
  getModelDescriptionRequest,
  getRangeDescriptionRequest,
} from "./service";

export const descriptionSelector = selector({
  key: "store.products.description.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, newValue) => {
    set(state, {
      value: newValue,
    });
  },
});

export const useSetDefaultDescriptionValue = () => {
  const setState = useSetRecoilState(descriptionSelector);
  return () => {
    setState({
      isLoading: false,
      type: null,
      description: {},
    });
  };
};

export const useRefreshDescription = () => {
  const [state, setState] = useRecoilState(descriptionSelector);

  return (page) => {
    let type = "range";
    if (page >= 5) {
      type = "model";
    }

    if (state?.value?.type !== type) {
      setState({ type, isLoading: true });

      if (type === "range") {
        getRangeDescriptionRequest().then((data) => {
          setState({ type, isLoading: false, description: data.data });
        });
      } else if (type === "model") {
        getModelDescriptionRequest().then((data) => {
          setState({ type, isLoading: false, description: data.data });
        });
      } else {
        setState({ type, isLoading: false });
      }
    }
  };
};


