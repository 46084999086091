import React from "react";
import "./Checkbox.css";

export default function Checkbox({ styles = {}, onClick = () => {}, isSelected }) {
  return (
    <div
      className="check-box-container"
      style={{ ...styles }}
      onClick={onClick}
    >
      {isSelected && <img className="check-box-checkmark" />}
    </div>
  );
}
