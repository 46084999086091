import { atom } from "recoil";

const state = atom({
  key: "store.userData.state",
  default: {
    firstName: { value: "", valid: true },
    lastName: { value: "", valid: true },
    organization: { value: "", valid: true },
    email: { value: "", valid: true },
    country: { value: "", valid: true },
    phone: { value: "", valid: true },
    street1: { value: "", valid: true },
    street2: { value: "", valid: true },
    street3: { value: "", valid: true },
    zip: { value: "", valid: true },
    city: { value: "", valid: true },
    reference: { value: "", valid: true },
  },
});

export default state;
