import ApiClient from "_config/client";
import types from "_store/types";

const base = "/material";

export const getMaterialsRequestBuilder = () => ApiClient.get(base);

export const selectMaterialRequestBuilder = (id) =>
  ApiClient.post(`${base}/${id}/select`);

export const getMaterials = (dispatch) => {
  dispatch({ type: types.started });
  getMaterialsRequestBuilder()
    .then(({ data }) => dispatch({ type: types.success, payload: data }))
    .catch((error) => dispatch({ type: types.error }));
};

const dispatchMutatingAction = (request, dispatch) => {
  dispatch({ type: types.started });

  request
    .then(() => getMaterials(dispatch))
    .catch((error) => dispatch({ type: types.error }));
};

export const selectMaterial = ({ id }, dispatch) =>
  dispatchMutatingAction(selectMaterialRequestBuilder(id), dispatch);
