import InputField from "components/InputField/InputField";
import React from "react";
import "./CountryDropDown.css";

export default function CountryDropDown({
  label,
  isInvalid,
  invalidText,
  countries,
  setSelectedCountry,
  showCustomInput,
  onCustomNameChange
}) {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      <div style={{width: showCustomInput ? "30%" : "100%"}}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="input-field-label">{label}</div>
          <div
            className="input-field-mandatory-label"
            style={{ opacity: isInvalid ? 1 : 0 }}
          >
            {invalidText}
          </div>
        </div>
        <select
          className="dropdown-container "
          onChange={(e) => setSelectedCountry(e?.target?.value)}
        >
          {countries?.map((country) =>
            country?.isDefault ? (
              <option selected id={country?.code}>
                {country?.name}
              </option>
            ) : (
              <option id={country?.code}>{country?.name}</option>
            )
          )}
        </select>
      </div>
      {showCustomInput && (
        <div style={{ display: "flex", alignItems: "end", width: "100%" }}>
          <InputField onChange={event => onCustomNameChange(event)}/>
        </div>
      )}
    </div>
  );
}
