import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import EstimateOverviewElement from "features/overview/EstimateOverview/EstimateOverviewElement";
import { useLayoutEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getReadOnlyModeStatus } from "_config/session";
import {
  configurationsSelector,
  useGetConfigurations,
} from "_store/configurations/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";
import { summarySelector } from "_store/summary/selectors";
import "./EstimateOverviewPage.css";

const EstimateOverviewPage = () => {
  const getLocalizationText = useGetLocalizationText();
  const summary = useRecoilValue(summarySelector);
  const configurations = useRecoilValue(configurationsSelector);
  const [isLoading, setIsLoading] = useState(false);

  const getItems = useGetConfigurations();

  const readOnlyMode = getReadOnlyModeStatus();

  useLayoutEffect(() => {
    setIsLoading(true);
    getItems(setIsLoading);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div className="estimate-overview-container">
          <div className="estimate-overview-header">{getLocalizationText("EstimateOverview_Title")}</div>
          <div className="estimate-overview-configuration-list-container">
            {configurations?.list?.map((element) => (
              <EstimateOverviewElement
                key={element.id}
                imageUrl={element.imageUrl}
                configuration={element}
                refreshList={() => {
                  setIsLoading(true);
                  getItems(setIsLoading)
                }}
                isLocked={summary?.isLocked}
                readOnlyMode={readOnlyMode}
              />
            ))}
          </div>
          {!readOnlyMode && (
            <EstimateOverviewElement type="create">
              {getLocalizationText("EstimateOverview_AddConfiguration")}
            </EstimateOverviewElement>
          )}
        </div>
      )}
    </>
  );
};

export default EstimateOverviewPage;
