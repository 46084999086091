import React from "react";
import { useGetLocalizationText } from "_store/localization/selectors";
import "./ConfigCabinetSummaryItem.css";
import "../ConfigSummaryItem/ConfigSummaryItem.css";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { priceFormater } from "__utilities/functions/Public";

export default function ConfigCabinetSummaryItem({
  image,
  productName,
  tempRangeDescription,
  dimension,
  material,
  refrigerant,
  price,
  currencyCode,
  cabinetDimension,
}) {
  const getLocalizationText = useGetLocalizationText();
  const regionObj = useGetSelectedRegionObject();

  return (
    <div className="config-cabinet-summary-item-container">
      <div className="config-cabinet-summary-item-container-left">
        <img className="config-summary-item-img" src={image} alt="" />
        <div>
          <div className="config-summary-item-title">
            {tempRangeDescription}
            {dimension && ", " + dimension}
            {material && ", " + material}
            {refrigerant && ", " + refrigerant}
          </div>
          <div className="config-summary-item-title">{cabinetDimension}</div>
          <div className="config-summary-item-price ">
            {price && getLocalizationText("Summary_Total_Title") + ": "}
            {priceFormater(
              price,
              currencyCode,
              regionObj?.localizationISOCode,
              regionObj?.currencyPosition
            )}
          </div>
        </div>
      </div>
      <div className="config-summary-item-code">{productName}</div>
    </div>
  );
}
