import state from "./index";
import types from "_store/types";
import { selector } from "recoil";

export const cabinetSelector = selector({
  key: "store.products.cabinets.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, action) => {
    switch (action.type) {
      case types.started:
        return set(state, {
          ...get(state),
          isLoading: true,
        });
      case types.success:
        return set(state, {
          ...get(state),
          cabinets: action.payload,
          isLoading: false,
        });
      case types.error:
        return set(state, {
          ...get(state),
          isLoading: false,
        });
      default:
        return set(state, ...get(state));
    }
  },
});

export const selectedSortOptionSelector = selector({
  key: "store.products.selectedSortOption.selector",
  get: ({ get }) => get(state).selectedSortOption,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      selectedSortOption: newValue,
    }),
});