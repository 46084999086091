import { useLayoutEffect, Fragment, useState } from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Configuration from "features/product/Configuration";
import { useGetLocalizationText } from "_store/localization/selectors";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import { useRecoilState, useRecoilValue } from "recoil";
import { materialSelector } from "_store/products/material/selectors";
import { getMaterials, selectMaterial } from "_store/products/material/service";
import { useActivePage } from "_store/page/selectors";
import useDidMountEffect from "__utilities/hooks/useDidMountEffect";

const MaterialPage = () => {
  const getLocalizationText = useGetLocalizationText();
  const {currPage} = useActivePage()
  const refreshSummary = useRefreshSummary();
  const [loaded, setLoaded] = useState(true);

  const [{ isLoading, materials: sections }, dispatch] =
    useRecoilState(materialSelector);

    const summary = useRecoilValue(summarySelector);

  useLayoutEffect(() => {
    refreshSummary(currPage);
    getMaterials(dispatch);
  }, []);

  useDidMountEffect(() => {
    setLoaded(false);
  }, [sections]);

  const isPageLocked =
    summary?.steps?.find((s) => s.number === currPage)?.isLocked !== false
      ? true
      : false;

  const currentlySelectedPriceAmount = sections.find(e => e.isSelected === true)?.price?.amount

  return (
    <div className="page-container">
      {isLoading && loaded ? (
        <div className="centred-container">
          <LoadingSpinner />
        </div>
      ) : (
        <Fragment>
          <div className="page-header-title">
            {getLocalizationText("Step3_Subtitle")}
          </div>
          {sections &&
            sections.map((element) => {
              return (
                <Configuration
                  key={element.id}
                  id={element.id}
                  title={element.title}
                  details={element.details}
                  price={element.price}
                  image={element.imageUrl}
                  isSelected={element.isSelected}
                  currentlySelectedPriceAmount={currentlySelectedPriceAmount}
                  userConfirmationRequired={element.userConfirmationRequired}
                  onClickHandler={(id) => {
                    id && selectMaterial({ id }, dispatch);
                  }}
                  isClickable={!isLoading && !isPageLocked}
                />
              );
            })}
        </Fragment>
      )}
    </div>
  );
};

export default MaterialPage;
