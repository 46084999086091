import { selector, useSetRecoilState } from "recoil";
import { summarySelector } from "_store/summary/selectors";
import state from "./index";
import { getConfigurationsRequest } from "./service";

export const configurationsSelector = selector({
  key: "store.configurations.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, list) =>
    set(state, {
      ...get(state),
      list,
    }),
});

export const useGetConfigurations = () => {
  const setSummary = useSetRecoilState(summarySelector);
  const setConfigurations = useSetRecoilState(configurationsSelector);

  return (dispatch) => {
    getConfigurationsRequest()
      .then((data) => {
        dispatch(false);
        setConfigurations(data.data);
        let sum = 0;
        let code = "";
        data?.data?.forEach((element) => {
          sum += element?.totalPrice?.amount;
          code = element?.totalPrice?.currency;
        });
        setSummary((prev) => ({
          ...prev,
          totalPrice: { currency: code, amount: sum },
        }));
      })
      .catch((error) => {
        dispatch(false);
        console.log(error);
      });
  };
};
