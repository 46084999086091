import ApiClient from "_config/client";
export const sendEmailToRequesterRequest = (userData) =>
  ApiClient.post("/request/email", userData);

export const sendQuotationRequest = (userData) =>
  ApiClient.post("/request/quotation", userData);

export const sendContactMeRequest = (userData) =>
  ApiClient.post("/request/contactMe", userData);

export const getCountryRequest = () => ApiClient.get("/country");
