import ContactSection from "features/contact/ContactSection";
import { useRecoilValue } from "recoil";
import { getReadOnlyModeStatus, getConfigurationId } from "_config/session";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import { summarySelector } from "_store/summary/selectors";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import {
  estimatePage,
  summaryPage,
  userDataPage,
} from "__utilities/PageStepEnum";
import CompartmentIndicatorContainer from "./CompartmentIndicator/CompartmentIndicatorContainer";
import DimensionsIndicator from "./DimensionsIndicator/DimensionsIndicator";
import TotalCostWidget from "./TotalCostWidget/TotalCostWidget";
import DeletePopupOpener from "features/deletePopup/DeletePopupOpener";
import { useLayoutEffect, useState } from "react";
import {
  configurationsSelector,
  useGetConfigurations,
} from "_store/configurations/selectors";

const { default: CustomButton } = require("components/Buttons/CustomButton");
const {
  default: VerticalProgressStepBar,
} = require("features/progress/VerticalProgressStepBar");
const { default: BioCode } = require("./BioCode/BioCode");

const SelectionStep = ({
  nextBtnClickHandler,
  prevBtnClickHandler,
  activePageState,
  currStepIsCompleted,
  setDiscardPopup,
  setDeletePopup,
}) => {
  const getLocalizationText = useGetLocalizationText();

  const [isTermsAccepted, setIsTearmsAccepted] = useState(false);

  const summary = useRecoilValue(summarySelector);
  const showCompartmentIndicator =
    summary?.cabinet?.comparment1?.fullSize?.spaceTotal > 0 &&
    activePageState === summaryPage;

  const { setPage } = useActivePage();
  const showCompartmentVerticalProgressStepBar = activePageState < summaryPage;
  const showBioCode = activePageState < estimatePage;

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const getConfigurations = useGetConfigurations();
  const configurations = useRecoilValue(configurationsSelector);
  const configurationId = getConfigurationId();

  const userDataTermsCheck =
    activePageState !== userDataPage || isTermsAccepted;

  let showDeletePopupIndicator = configurations?.list?.length > 0;
  if (
    configurations?.list?.length === 1 &&
    configurations.list[0].id === configurationId
  ) {
    showDeletePopupIndicator = false;
  }

  const separateLinkFromString = (text, url) => {
    let array = [];
    array = text?.split("$");
    return (
      <>
        {array?.map?.((subString, index) => {
          if (index % 2 === 0) {
            return <span>{subString}</span>;
          } else {
            return (
              <a
                href={url}
                target="_blank"
                style={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {subString}
              </a>
            );
          }
        })}
      </>
    );
  };

  const getTermsCheckBox = () => (
    <div style={{ display: "flex" }}>
      <input
        type="checkbox"
        checked={isTermsAccepted}
        onClick={() => setIsTearmsAccepted((prev) => !prev)}
      />
      <span>
        {separateLinkFromString(
          getLocalizationText("CollectUserData_TermsAndConditions_Text"),
          getLocalizationText("CollectUserData_TermsAndConditions_Url")
        )}
      </span>
    </div>
  );

  useLayoutEffect(() => {
    getConfigurations(() => {});
  }, []);

  const getSelectionTillSummary = () => {
    return (
      <div style={{ marginTop: "10%" }}>
        {showDeletePopupIndicator && !getReadOnlyModeStatus() && (
          <div style={{ width: "85%", marginBottom: "15px" }}>
            <DeletePopupOpener onClick={() => setDeletePopup(true)} />
          </div>
        )}

        <div className="configuration-manu-container">
          {showCompartmentVerticalProgressStepBar && (
            <div className="configuration-menu-title">
              {getLocalizationText("Summary_Title")}
            </div>
          )}
          <div className="configuration-menu-summary-container">
            {showCompartmentVerticalProgressStepBar && (
              <VerticalProgressStepBar activePage={activePageState} />
            )}
            {showCompartmentIndicator && (
              <CompartmentIndicatorContainer
                disabled={activePageState >= summaryPage ? true : false}
                style={{ marginBottom: "20px" }}
              />
            )}
            {showBioCode && (
              <BioCode
                title={getLocalizationText("Summary_BioCodeUltra_Title")}
              />
            )}
          </div>
          <DimensionsIndicator />
          <TotalCostWidget title={getLocalizationText("Summary_Total_Title")} />
          {getReadOnlyModeStatus() ? (
            <CustomButton
              text={"Back"}
              type={"next"}
              onClick={() => {
                setPage(estimatePage);
              }}
            />
          ) : (
            <>
              <CustomButton
                text={getLocalizationText("Global_Button_Next")}
                type={currStepIsCompleted ? "next" : "next disabled"}
                onClick={nextBtnClickHandler}
              />
              <CustomButton
                text={getLocalizationText("Global_Button_Previous")}
                type="previous secondary"
                onClick={prevBtnClickHandler}
              />

              <CustomButton
                text={getLocalizationText("Global_Button_Discard")}
                type="discard"
                onClick={() => setDiscardPopup(true)}
              />
            </>
          )}
          <ContactSection />
        </div>
      </div>
    );
  };

  const getSelectionAfterSummary = () => {
    return (
      <div className="configuration-manu-container summary">
        <div style={{ marginBottom: "40px", marginTop: "40px" }}>
          <TotalCostWidget title={getLocalizationText("Summary_Total_Title")} />
          {!getReadOnlyModeStatus() && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                {activePageState > estimatePage && (
                  <CustomButton
                    text={getLocalizationText("Global_Button_Previous")}
                    type="previous"
                    onClick={prevBtnClickHandler}
                  />
                )}
                <CustomButton
                  text={
                    activePageState === userDataPage
                      ? getLocalizationText("CollectUserData_Button_Finish")
                      : getLocalizationText("Global_Button_Next")
                  }
                  type={
                    currStepIsCompleted && userDataTermsCheck
                      ? "next"
                      : "next disabled"
                  }
                  onClick={nextBtnClickHandler}
                />
              </div>
              {activePageState === userDataPage && getTermsCheckBox()}
            </div>
          )}
        </div>
      </div>
    );
  };

  const getSmallScreenSelection = () => {
    return (
      <div className="configuration-manu-container summary">
        <div
          style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}
        >
          <TotalCostWidget title={getLocalizationText("Summary_Total_Title")} />
          {!getReadOnlyModeStatus() ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", width: "100%", gap: "10px" }}>
                {activePageState !== estimatePage && (
                  <CustomButton
                    style={{ width: "100%" }}
                    text={getLocalizationText("Global_Button_Previous")}
                    type="previous"
                    onClick={prevBtnClickHandler}
                  />
                )}
                <CustomButton
                  style={{
                    width: "100%",
                  }}
                  text={getLocalizationText("Global_Button_Next")}
                  type={
                    currStepIsCompleted && userDataTermsCheck
                      ? "next"
                      : "next disabled"
                  }
                  onClick={nextBtnClickHandler}
                />
              </div>
              {activePageState === userDataPage && getTermsCheckBox()}
            </div>
          ) : (
            activePageState === summaryPage && (
              <CustomButton
                style={{ width: "100%", padding: "0px" }}
                text={"Back"}
                type={"next"}
                onClick={() => {
                  setPage(estimatePage);
                }}
              />
            )
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="configuration">
      {isSmallScreen || isMediumScreen
        ? getSmallScreenSelection()
        : activePageState < estimatePage
        ? getSelectionTillSummary()
        : getSelectionAfterSummary()}
    </div>
  );
};
export default SelectionStep;
