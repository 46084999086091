import state from "./index";
import { selector, useRecoilValue } from "recoil";

export const localizationSelector = selector({
  key: "store.localization.selector",
  get: ({ get }) => get(state).localization,
  set: ({ get, set }, newValue) => {
    set(state, {
      ...get(state),
      localization: newValue,
    });
  },
});

export const convertLocalization = (data) => {
  // let newLocalization = {};
  // data[0].texts.forEach((element) => {
  //   newLocalization[element.key] = element;
  // });

  // return newLocalization;
  
  return data[0].texts
};

export const useGetLocalizationText = () => {
  const localization = useRecoilValue(localizationSelector);
  
  return (key) => {
    if (localization[key] && localization[key]) {
      return localization[key];
    }
    return "[" + key + "]";
  };
};
