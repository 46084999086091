import { cloneOptions, sortOptions } from "__utilities/functions/Public";
import "./CompartmentIndicator.css";

function fillCompartmentArray(array, max, occupied) {
  let diff = max - occupied;
  for (let elm = 0; elm < diff; elm++) {
    array.push(null);
  }
  return array.reverse();
}
function convertOccupiedSpaceToArray(array, type) {
  let result = [];
  if (array) {
    array.map((item, index) => {
      if (item.occupiedSpace === 0) {
        result.push(
          <div
            key={`${item.compartment}-${item.id}-${index}`}
            title={item.title}
            style={{
              backgroundColor:
                index % 2 === 0 ? "var(--hoverGreen)" : "var(--secondaryGreen)",
              borderColor:
                index % 2 === 0 ? "var(--hoverGreen)" : "var(--secondaryGreen)",
            }}
            className={`compartment-indicator${
              type === "300mm" ? "-300mm" : ""
            }-item rowSpan-${item.occupiedSpace / item.quantity}`}
          >
            <span>
              {item.id} {item.quantity > 1 ? " x " + item.quantity : ""}
            </span>
          </div>
        );
      } else {
        for (let x = 0; x < item.quantity; x++) {
          result.push(
            <div
              key={`${item.compartment}-${item.id}-${index}-${x}`}
              title={item.title}
              style={{
                backgroundColor:
                  index % 2 === 0
                    ? "var(--hoverGreen)"
                    : "var(--secondaryGreen)",
                borderColor:
                  index % 2 === 0
                    ? "var(--hoverGreen)"
                    : "var(--secondaryGreen)",
              }}
              className={`compartment-indicator${
                type === "300mm" ? "-300mm" : ""
              }-item rowSpan-${item.occupiedSpace / item.quantity}`}
            >
              <span>{item.id}</span>
            </div>
          );
        }
      }
    });
  }

  return result;
}
const CompartmentIndicator = ({
  id,
  type,
  options,
  mm300Options,
  spaceOccupied,
  mm300spaceOccupied,
  spaceTotal,
  mm300SpaceTotal,
  isSelected,
  onClickHandler,
}) => {
  return (
    <div
      className="compartment-indicator-container"
      onClick={() => onClickHandler(id)}
    >
      <div
        className={`compartment-indicator-main-space-container${
          isSelected ? " selected" : ""
        }${type === "small" ? " small" : ""}${
          type === "large" ? " large" : ""
        }`}
      >
        {fillCompartmentArray(
          convertOccupiedSpaceToArray(cloneOptions(options)?.sort(sortOptions)),
          spaceTotal,
          spaceOccupied
        ).map((item, index) => {
          if (item === null) {
            return (
              <div
                key={`empty-fullSize-${index}`}
                className="compartment-indicator-item empty"
              />
            );
          } else {
            return item;
          }
        })}

        {fillCompartmentArray(
          convertOccupiedSpaceToArray(
            cloneOptions(mm300Options)?.sort(sortOptions),
            "300mm"
          ),
          mm300SpaceTotal,
          mm300spaceOccupied
        ).map((item, index) => {
          if (item === null) {
            return (
              <div
                key={`empty-300mm-${index}`}
                className="compartment-indicator-300mm-item empty"
              />
            );
          } else {
            return item;
          }
        })}
      </div>
    </div>
  );
};

export default CompartmentIndicator;
