import { selector, useRecoilState, useSetRecoilState } from "recoil";
import { bioCodeRowCells, emptySigne } from "__utilities/Public";
import state from "./index";
import { getSummaryRequest } from "./service";
import { rangeSelector } from "_store/selected/selectors";

export const summarySelector = selector({
  key: "store.summary.selector",
  get: ({ get }) => get(state).summary,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      summary: newValue,
    }),
});

export const useRefreshSummary = () => {
  const [{ steps, bioCode, totalPrice, cabinet }, setSummary] =
    useRecoilState(summarySelector);
  const setRange = useSetRecoilState(rangeSelector);

  return (stepNumber, dispatch) => {
    setSummary({ isLoading: true, steps, bioCode, totalPrice, cabinet });
    getSummaryRequest(stepNumber).then((data) => {
      setRange(data.data.steps?.[1]?.text);
      //console.log(data.data)
      setSummary({
        isLoading: false,
        bioCode: data.data.bioCode,
        steps: data.data.steps,
        totalPrice: data.data.totalPrice,
        cabinet: data.data.cabinet,
        isValid: data.data.isValid,
        isLocked: data.data.isLocked,
      });
      
      if (dispatch) {
        dispatch(data.data.steps);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };
};

export const bioCodeFormater = (codes) => {
  let codeArray = codes;
  let startSlicePlace = 0;
  let endSlicePlace = 0;
  let sliceArray = [];

  while (endSlicePlace < codeArray.length) {
    startSlicePlace = endSlicePlace;
    endSlicePlace += bioCodeRowCells;
    let slice = codeArray.slice(startSlicePlace, endSlicePlace);

    if (slice.length < bioCodeRowCells) {
      for (let i = 0; bioCodeRowCells - slice.length; i++) {
        slice.push(emptySigne);
      }
    }

    sliceArray.push(slice);
  }

  return sliceArray;
};
