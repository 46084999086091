import state from "./index";
import { selector } from "recoil";
export const userDataSendingStateSelector = selector({
  key: "store.userData.userData.sending.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      isSending: newValue,
    }),
});
