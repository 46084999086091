import "./CustomButton.css";

const CustomButton = ({ text, onClick, type, style }) => {
  return (
    <div onClick={onClick} className={`custom-button-container-${type}`} style={{...style}}>
      {text}
    </div>
  );
};

export default CustomButton;
