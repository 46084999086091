import React from "react";
import Checkbox from "components/Checkbox/Checkbox";

export default function AdvancedFilterElement({
  item: { isSelected, title, count },
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox isSelected={isSelected} />
        <span style={{ marginLeft: "10px" }}>{title}</span>
      </div>
      {count > 0 && <span style={{ marginLeft: "10px" }}>({count})</span>}
    </div>
  );
}
