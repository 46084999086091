import { atom } from "recoil";

const state = atom({
  key: "store.products.options.state",
  default: {
    isLoading: false,
    options: []
  },
});

export default state;