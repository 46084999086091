import ApiClient from "_config/client";

export const getProductsRequest = (filter) => {
  return ApiClient.get("/cabinet", { params: filter });
};

export const getRangeDescriptionRequest = () => ApiClient.get("/description/range");

export const getModelDescriptionRequest = () => ApiClient.get("/description/model");

