import { atom } from "recoil";

const state = atom({
  key: "store.selected.state",
  default: {
    range:null,
  },
});

export default state;
