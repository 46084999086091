import { atom } from "recoil";

const state = atom({
  key: "store.localization.state",
  default: {
    localization: {
      //Step1_Title
      //StepOverview_Title_Configure
      //Step1_Subtitle
      //Step1_Filter_Width
      //Step1_Filter_Depth
      //Step1_Filter_Height
      //Step1_Filter_Volume
      //Step1_Filter_TempRange
      //StepOverview_Step1
      //StepOverview_Step2
      //StepOverview_Step3
      //StepOverview_Step4
      //StepOverview_Step5
      //StepOverview_Step6
      //StepOverview_Step7
      //StepOverview_Step8
      //StepOverview_Step9
      //StepOverview_Step10
      //Global_Button_Configure
      //Global_Button_ReadSpec
      //Global_Button_Next
      //Global_Button_Previous
      //Summary_Total_Title
      //Summary_Title
      //Summary_BioCodeUltra_Title
      //Step2_Subtitle
      //Step3_Subtitle
      //Step4_Subtitle
      //Step5_Subtitle

      //Step1_RegionDropDown_Label
      //ChooseInformType_Title
      //ChooseInformType_CreateConfigID_Text
      //ChooseInformType_SendQuotation_Text
      //ChooseInformType_ContactMe_Text
      //CollectUserData_SendingPopup_Text
      //CollectUserData_CreateConfigID_Title
      //CollectUserData_SendQuotation_Title
      //ColectUserData_ContactMe_Title
      //CollectUserData_FirstName_Label
      //CollectUserData_LastName_Label
      //CollectUserData_Organisation_Label
      //CollectUserData_Street1_Label
      //CollectUserData_Street2_Label
      //CollectUserData_Street3_Label
      //CollectUserData_Zip_Label
      //CollectUserData_City_Label
      //CollectUserData_Country_Label
      //CollectUserData_Email_Label
      //CollectUserData_Phone_Label
      //ConfirmationPage_Text
      //ConfirmationPage_Button_Text
      //EstimateOverview_Title
      //EstimateOverview_AddConfiguration
      //InputFieldValidation_Mandatory_Text
      //InputFieldValidation_BadEmailFormat_Text
    },
  },
});

export default state;
