import CustomButton from "components/Buttons/CustomButton";
import React from "react";
import { useRecoilValue } from "recoil";
import {
  getAcquireQuotationRequestMessage,
  setNewSessionId,
  getUserSelection,
  setUserSelection,
  getRegion,
  setRegion,
} from "_config/session";
import { newConfiguration } from "_store/configurations/service";
import { informingTypeSelector } from "_store/informingType/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import { clearSessionStorage } from "__utilities/functions/Public";
import { chooseRangePage } from "__utilities/PageStepEnum";
import "./ConfirmationPage.css";

export default function ConfirmationPage() {
  const getLocalizationText = useGetLocalizationText();
  const { setPage } = useActivePage();
  const { informingType } = useRecoilValue(informingTypeSelector);

  const isAcquireQuotationMessage = getAcquireQuotationRequestMessage();

  const userSelection = informingType ? informingType : getUserSelection();
  setUserSelection(userSelection);

  return (
    <div className="confirmation-page-container">
      <div className="confirmation-page-inner-wrapper">
        <div className="confirmation-page-check-mark" />
        <div className="confirmation-page-text">
          {isAcquireQuotationMessage
            ? isAcquireQuotationMessage
            : userSelection === "createConfigurationID"
            ? getLocalizationText("ConfirmationPage_CreateConfigID_Text")
            : userSelection === "quotation"
            ? getLocalizationText("ConfirmationPage_SendQuotation_Text")
            : userSelection === "contactMe" &&
              getLocalizationText("ConfirmationPage_ContactMe_Text")}
        </div>
        <CustomButton
          text={getLocalizationText("ConfirmationPage_Button_Text")}
          onClick={() => {
            setUserSelection('')
            let region = getRegion()// reserving region in next configuration
            clearSessionStorage();
            setRegion(region)
            setNewSessionId();
            newConfiguration(() => setPage(chooseRangePage));
          }}
          style={{ width: "200px", marginTop: "20px" }}
          type="next"
        ></CustomButton>
      </div>
    </div>
  );
}
