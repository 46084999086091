import ApiClient from "../../_config/client";

export const getRegionsRequest = () => ApiClient.get("/region");
export const getSessionRegionRequest = () => ApiClient.get("/region/get");

export const requestOfficialQuotationRequest = () =>
  ApiClient.post("request/officialQuotation");

export const validateDistributorRequest = () => ApiClient.get("distributor/me");

export const getSortOptionsRequest = () => ApiClient.get("/region/sort");

export const getSortOptions = async () => {
  try {
    const response = await getSortOptionsRequest();

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error, data: null };
  }
};

export const validateDistributor = async () => {
  try {
    const response = await validateDistributorRequest();

    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error, data: null };
  }
};

export const getSessionRegion = async () => {
  try {
    const response = await getSessionRegionRequest();
    
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, error, data: null };
  }
};
