import { useAdvancedFilter } from "_store/filter/selectors";
import "./AdvancedFilterOverview.css";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";

export default function AdvancedFilterOverview({ onFilterChange, styles }) {
  const { getSelectedFilters, onItemChangeHandler, clearAdvancedFilters } =
    useAdvancedFilter();

  const getLocalizationText = useGetLocalizationText();

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const onFilterChangeHandler = (tag) => {
    const newFilter = onItemChangeHandler(tag);
    onFilterChange(newFilter);
  };

  const onFilterClear = () => {
    clearAdvancedFilters();
    onFilterChange(null);
  };

  const filters = getSelectedFilters();

  let hasActiveFilters = filters?.find((element) => element?.items?.length > 0)
    ? true
    : false;

  return (
    <div style={{ ...styles }}>
      {filters?.map((category) =>
        category?.items?.map((item) => (
          <div style={{ display: "flex", marginBottom: "5px" }}>
            <div
              className="popup-xicon"
              style={{ marginRight: "15px", objectFit: "contain" }}
              onClick={() => onFilterChangeHandler(item.tag)}
            />
            {category.title}: {item.title}
          </div>
        ))
      )}
      {hasActiveFilters && !isSmallScreen && !isMediumScreen && (
        <div
          onClick={onFilterClear}
          style={{
            width: "100%",
            height: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid var(--primaryGreen)",
            borderRadius: "5px",
          }}
        >
          {getLocalizationText("Global_Button_ClearFilter")}
        </div>
      )}
    </div>
  );
}
