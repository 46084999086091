import { useGetLocalizationText } from "_store/localization/selectors";

export default function InvalidDistributorCodePage() {
  const getLocalizationText = useGetLocalizationText();
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "30px",
        fontFamily: "HelveticaNeue-Roman",
      }}
    >
      {getLocalizationText("Distributor_Code_Invalid")}
    </div>
  );
}
