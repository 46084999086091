import { atom } from "recoil";

const state = atom({
  key: "store.products.energyTypes.state",
  default: {
    isLoading: false,
    energyTypes: []
  },
});

export default state;