import { atom } from "recoil";

const state = atom({
  key: "store.filterLimit.state",
  default: {
    filter: {
      minWidth: 0,
      maxWidth: 0,
      minDepth: 0,
      maxDepth: 0,
      minHeight: 0,
      maxHeight: 0,
      minVolume: 0,
      maxVolume: 0,
      minTemperatureRange: 0,
      maxTemperatureRange: 0,
    },
  },
});

export default state;