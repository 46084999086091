import { useRecoilValue } from "recoil";
import { summarySelector } from "_store/summary/selectors";
import "./VerticalProgressStepBar.css";

const VerticalProgressStepBar = ({ activePage }) => {
  const summary = useRecoilValue(summarySelector);
  //const isLoading = summary.isLoading;
  const steps = summary.steps;

  return (
    <div className="vet-step-bar-container">
      {steps.map((element) => (
        <div key={`step-${element.number}`}>
          {element.text ? (
            <div
              className={
                activePage >= element.number ? "step step-active" : "step"
              }
            >
              <div>
                <div className="vet-step-bar-circle">{element.number}</div>
              </div>
              <div
                className={`vet-step-bar-title ${
                  element.isCompleted ? "bolded" : "blured"
                }`}
              >
                {element.text}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};

export default VerticalProgressStepBar;
