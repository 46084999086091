import {
  addSignToFormatedPrice,
  getUuid,
  priceFormater,
} from "__utilities/functions/Public";
import { useState } from "react";
import CustomRadioButton from "../../components/Buttons/CustomRadioButton";
import "./Configuration.css";
import CardOverlay from "./disableReason/CardOverlay";
import { Fragment } from "react";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import CardSeparator from "components/CardSeparator/CardSeparator";

const Configuration = ({
  type,
  id,
  designator,
  title,
  details,
  price,
  image,
  isSelected,
  currentlySelectedPriceAmount,
  onClickHandler,
  isClickable,
  userConfirmationRequired,
}) => {
  const [isPopUpShown, setIsPopUpShown] = useState(false);
  const getLocalizationText = useGetLocalizationText();
  const { currPage } = useActivePage();

  const regionObj = useGetSelectedRegionObject();

  const isSmallScreen = useIsSmallScreen();

  const onCardClickHandler = () => {
    if (userConfirmationRequired) {
      setIsPopUpShown(true);
    } else {
      onClickHandler(id);
    }
  };

  const getItemDetails = () => (
    <div className="card-info-text">
      <div className="card-info-title">{title}</div>
      <div className="card-info-stats">
        {type === "energy" ? (
          <div className="card-info-text">
            <div className="card-info-stats">
              <div className="card-info-stats-title">{details}</div>
            </div>
          </div>
        ) : (
          details.map((element) => (
            <div key={getUuid()} className="card-info-text">
              <div className="card-info-stats">
                <div className="card-info-stats-title">
                  {element.name}:
                  <span className="card-info-stats"> {element.value}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );

  const getItemBioCode = () => (
    <div className={"card-info-bio-code-container" + (isSmallScreen ? " smallScreen" : "")}>
      <div className="card-info-bio-code">{designator ? designator : id}</div>
      {!isSelected && (
        <div className="card-info-price">
          {currentlySelectedPriceAmount
            ? addSignToFormatedPrice(
                price.amount - currentlySelectedPriceAmount,
                price.currencyCode,
                regionObj?.localizationISOCode,
                regionObj?.currencyPosition
              )
            : priceFormater(
                price.amount,
                price.currencyCode,
                regionObj?.localizationISOCode,
                regionObj?.currencyPosition
              )}
        </div>
      )}
    </div>
  );

  const getItemImage = () => (
    <div className="card-info-image-container">
      <img className="card-info-image" src={image} alt="" />
    </div>
  );

  return (
    <div
      className="card-container"
      onClick={
        isClickable ? (!isSelected ? onCardClickHandler : () => {}) : () => {}
      }
    >
      {!isSmallScreen && <CustomRadioButton state={isSelected} />}
      <div className={(isSelected ? "card selected" : "card") + (isSmallScreen ? " smallScreen": "")}>
        {isPopUpShown ? (
          <CardOverlay
            text={getLocalizationText(
              `Step${currPage ? currPage : ""}_UserConfirmation_ChangeModel`
            )}
            type={"confirmation"}
            action={(result) => {
              if (result === "yes") {
                onClickHandler(id);
              }
              setTimeout(() => {
                setIsPopUpShown(false);
              }, 10);
            }}
          />
        ) : (
          <Fragment>
            <div className="card-info-left-container">
              {type !== "energy" && getItemImage()}
              {!isSmallScreen && getItemDetails()}
            </div>
            {isSmallScreen && type !== "energy" && <CardSeparator />}
            {isSmallScreen ? (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", width: "100%" }}>
                {getItemDetails()}
                {getItemBioCode()}
              </div>
            ) : (
              <>{getItemBioCode()}</>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Configuration;
