export const chooseRegionPage = 0;
export const chooseRangePage = 1;
export const dimensionsPage = 2
export const materialPage = 3
export const energyTypePage = 4
export const optionsPage = 5 // pages 
export const summaryPage = 100
export const estimatePage = 101
export const informingTypePage = 102
export const userDataPage = 103
export const confirmationPage = 104