import CardSeparator from "components/CardSeparator/CardSeparator";
import ConfigCabinetSummaryItem from "features/configSummary/ConfigCabinetSummaryItem/ConfigCabinetSummaryItem";
import ConfigSummaryItem from "features/configSummary/ConfigSummaryItem/ConfigSummaryItem";
import VerticalProgressStepBar from "features/progress/VerticalProgressStepBar";
import BioCode from "features/selection/BioCode/BioCode";
import CompartmentIndicatorContainer from "features/selection/CompartmentIndicator/CompartmentIndicatorContainer";
import { useLayoutEffect } from "react";
import { useRecoilValue } from "recoil";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import {
  descriptionSelector,
  useRefreshDescription,
} from "_store/selected/Description/selectors";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import { cloneOptions, sortOptions } from "__utilities/functions/Public";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { isIterable } from "__utilities/Public";
import "./ConfigurationsSummaryPage.css";

const ConfigurationsSummaryPage = () => {
  const getLocalizationText = useGetLocalizationText();

  const data = useRecoilValue(descriptionSelector);
  const description = data.value?.description;
  const isLoading = data.value?.isLoading;

  const summary = useRecoilValue(summarySelector);

  const refreshSummary = useRefreshSummary();
  const refreshDescription = useRefreshDescription();

  const { currPage } = useActivePage();

  const isSmallScreen = useIsSmallScreen();

  const globalOptions = isIterable(summary?.cabinet?.globalOptions?.options)
    ? summary?.cabinet?.globalOptions?.options
    : [];
  const compartment1fullSize = isIterable(
    summary?.cabinet?.comparment1?.fullSize?.options
  )
    ? summary?.cabinet?.comparment1?.fullSize?.options
    : [];
  const compartment2fullSize = isIterable(
    summary?.cabinet?.comparment2?.fullSize?.options
  )
    ? summary?.cabinet?.comparment2?.fullSize?.options
    : [];
  const compartment1mM300 = isIterable(
    summary?.cabinet?.comparment1?.mM300?.options
  )
    ? summary?.cabinet?.comparment1?.mM300?.options
    : [];
  const compartment2mM300 = isIterable(
    summary?.cabinet?.comparment2?.mM300?.options
  )
    ? summary?.cabinet?.comparment2?.mM300?.options
    : [];

  function uniqueOptions(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i]?.id === a[j]?.id) {
          a[i].quantity = (a[i].quantity ?? 0) + (a[j]?.quantity ?? 0);
          a[i].chargableQuantity =
            (a[i].chargableQuantity ?? 0) + (a[j]?.chargableQuantity ?? 0);
          a.splice(j--, 1);
        }
      }
    }

    return a;
  }

  const optionArray = uniqueOptions(
    cloneOptions(globalOptions)
      .concat(cloneOptions(compartment1fullSize))
      .concat(cloneOptions(compartment2fullSize))
      .concat(cloneOptions(compartment1mM300))
      .concat(cloneOptions(compartment2mM300))
  ).sort(sortOptions);

  useLayoutEffect(() => {
    refreshSummary("");
    refreshDescription(currPage);
  }, []);

  return (
    <div className="configuration-summary-overview-container">
      {isSmallScreen && <CardSeparator />}
      {isSmallScreen && <VerticalProgressStepBar activePage={currPage} />}
      <ConfigCabinetSummaryItem
        image={description?.imageUrl}
        productName={!isSmallScreen && summary?.cabinet?.productName}
        tempRangeDescription={summary?.cabinet?.tempRangeDescription}
        dimension={summary?.steps?.[1]?.text}
        material={summary?.steps?.[2]?.text}
        refrigerant={summary?.steps?.[3]?.text}
        price={summary?.cabinet?.price?.amount}
        currencyCode={summary?.totalPrice?.currencyCode}
        cabinetDimension={`${summary?.cabinet?.dimensionLabel ?? ""}: ${
          summary?.cabinet?.dimensionValue ?? ""
        }`}
      />
      <div
        className={
          "configuration-summary-overview-items-container" +
          (isSmallScreen ? " smallScreen" : "")
        }
      >
        {optionArray.map((o) => (
          <ConfigSummaryItem
            key={o.id}
            id={o.id}
            image={o.imageUrl}
            type={o.type}
            quantity={o.quantity}
            title={o.title}
            price={o.price.amount}
            currencyCode={o.price.currencyCode}
            chargableQuantity={o.chargableQuantity}
          />
        ))}
      </div>
      {isSmallScreen && (
        <div className="configuration-summary-overview-indicator-biocode-container">
          <CompartmentIndicatorContainer
            disabled={true}
            style={{ marginBottom: "30px" }}
          />
          <BioCode title={getLocalizationText("Summary_BioCodeUltra_Title")} />
        </div>
      )}
    </div>
  );
};

export default ConfigurationsSummaryPage;
