import state from "./index";
import { selector, useRecoilValue, useSetRecoilState } from "recoil";
import { getAdvancedFilter } from "./service";
import { getCommaSeparatedStringFromArray } from "__utilities/functions/Public";
import { selectedSortOptionSelector } from "_store/products/cabinets/selectors";
import { sortOptionsSelector } from "_store/session/selectors";

export const filterSelector = selector({
  key: "store.filter.selector",
  get: ({ get }) => get(state).filter,
  set: ({ get, set }, newValue) => {
    set(state, {
      ...get(state),
      filter: newValue,
    });
  },
});

export const advancedFilterSelector = selector({
  key: "store.advancedFilter.selector",
  get: ({ get }) => get(state).advancedFilter,
  set: ({ get, set }, newValue) => {
    set(state, {
      ...get(state),
      advancedFilter: newValue,
    });
  },
});

export const advancedFilterIsLoadingSelector = selector({
  key: "store.advancedFilterIsLoading.selector",
  get: ({ get }) => get(state).advancedFilterIsLoading,
  set: ({ get, set }, newValue) => {
    set(state, {
      ...get(state),
      advancedFilterIsLoading: newValue,
    });
  },
});

export const useAdvancedFilter = () => {
  const setAdvancedFilter = useSetRecoilState(advancedFilterSelector);
  const setAdvancedFilterIsLoading = useSetRecoilState(
    advancedFilterIsLoadingSelector
  );
  const setSelectedSortedOption = useSetRecoilState(selectedSortOptionSelector);
  const sortOptions = useRecoilValue(sortOptionsSelector);

  const advancedFilter = useRecoilValue(advancedFilterSelector);

  const setupAdvancedFilter = async (filter) => {
    setAdvancedFilterIsLoading(true);
    let { success, data: categories, error } = await getAdvancedFilter(filter);
    setAdvancedFilterIsLoading(false);

    if (success) {
      mutateCurrentFilterState(categories);
      return true;
    } else {
      return false;
    }
  };

  const mutateCurrentFilterState = (categories) => {
    setAdvancedFilter((prev) =>
      categories.map((category, index) => ({
        ...category,
        isOpen: prev?.[index]?.isOpen ?? false,
        items: category?.items?.map((item) => ({
          ...item,
          isSelected: isItemSelected(prev, index, item.tag),
        })),
      }))
    );
  };

  const isItemSelected = (prev, index, tag) =>
    prev?.[index]?.items?.find((item) => item.tag === tag)?.isSelected ?? false;

  const onItemChangeHandler = (tag) => {
    let nextState = null;
    setAdvancedFilter((prev) => {
      nextState = prev?.map((category) => ({
        ...category,
        items: category?.items.map((item) => ({
          ...item,
          isSelected: item.tag === tag ? !item.isSelected : item.isSelected,
        })),
      }));
      return nextState;
    });
    return nextState;
  };

  const getCombinedFilterResult = (
    sliderFilterm,
    advancedFilterContent,
    sortBy
  ) => ({
    ...sliderFilterm,
    tags: getCommaSeparatedStringFromArray(
      getTagsFromSelectedItems(advancedFilterContent)
    ),
    sortBy: sortBy,
  });

  const getTagsFromSelectedItems = (advancedFilterContent) => {
    let tags = [];

    advancedFilterContent?.forEach((category) =>
      category?.items?.forEach((item) => {
        if (item.isSelected === true) {
          tags.push(item.tag);
        }
      })
    );
    return tags;
  };

  const clearFilterCategory = (categoryTitle) => {
    let nextState = null;
    setAdvancedFilter((prev) => {
      nextState = prev?.map((category) => ({
        ...category,
        items: category?.items?.map((item) => {
          if (category.title === categoryTitle) {
            return { ...item, isSelected: false };
          } else {
            return item;
          }
        }),
      }));
      return nextState
    });
    return nextState
  };

  const clearAdvancedFilters = () => {
    setAdvancedFilter(null);
  };

  const refreshAdvancedFilter = () => {
    setAdvancedFilter(null);

    const firstSortOption = sortOptions.length > 0 && sortOptions[0].value;
    firstSortOption && setSelectedSortedOption(firstSortOption);
  };

  const getSelectedFilters = () =>
    advancedFilter?.map((category) => ({
      ...category,
      items: category?.items?.filter((item) => item.isSelected),
    }));

  return {
    refreshAdvancedFilter,
    setupAdvancedFilter,
    getTagsFromSelectedItems,
    onItemChangeHandler,
    getCombinedFilterResult,
    getSelectedFilters,
    clearAdvancedFilters,
    clearFilterCategory,
  };
};
