import "./BioCodeCell.css";

const BioCodeCell = ({ active, data }) => {
  return (
    <div className={active ? "bio-code-cell active" : "bio-code-cell"}>
      <div
        className={
          "bio-code-cell-content" +
          (data?.length > 4 && data?.length < 6
            ? " fontSmall"
            : data?.length >= 6
            ? " fontSuperSmall"
            : "")
        }
      >
        {data && data}
      </div>
    </div>
  );
};

export default BioCodeCell;
