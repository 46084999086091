import React from "react";
import "./InformingTypeElement.css";

export default function InformingTypeElement({
  children,
  isSelected,
  onClick,
}) {
  return (
    <div
      onClick={onClick}
      className={
        "informing-type-element-container" + (isSelected ? " selected" : "")
      }
    >
      {children}
    </div>
  );
}
