import "./DescriptionWidget.css";
import { Fragment, memo, useEffect } from "react";
import LoadingSpinnerCircle from "components/LoadingSpinner/LoadingSpinnerCircle";
import { useRecoilValue } from "recoil";
import {
  descriptionSelector,
  useRefreshDescription,
} from "_store/selected/Description/selectors";
import { useActivePage } from "_store/page/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";

const DescriptionWidget = ({style}) => {
  const getLocalizationText = useGetLocalizationText();
  const data = useRecoilValue(descriptionSelector);
  const description = data.value?.description;
  const isLoading = data.value?.isLoading;

  const refreshDescription = useRefreshDescription();

  const { currPage, prevPage, nextPage } = useActivePage();

  useEffect(() => {
    refreshDescription(currPage);
  }, [currPage]);

  return (
    <Fragment>
      {isLoading ? (
        <div className="centred-container">
          <LoadingSpinnerCircle />
        </div>
      ) : (
        <div className="description-widget-container" style={{...style}}>
          <div className="description-widget-image-container">
            <img
              className="description-widget-image"
              src={description?.imageUrl}
              alt=""
            />
          </div>
          <div className="description-widget-title">{description?.title}</div>
          <div className="description-widget-text">
            {description?.text?.split("\n").map(function (item, idx) {
              return (
                <span key={idx}>
                  {item}
                  <br />
                </span>
              );
            })}
          </div>

          <div className="description-widget-link-container">
            <a href={description?.link} target="_blank" rel="noreferrer">
              {getLocalizationText("Global_Button_ReadSpec")?.toUpperCase()}
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default memo(DescriptionWidget);
