import { Fragment } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { optionsSelector } from "_store/products/options/selectors";
import {
  selectManyOption,
  deselectOption,
} from "_store/products/options/service";
import OptionSummaryElement from "./OptionSummaryElement";
import "./OptionSummary.css";
import { summarySelector } from "_store/summary/selectors";
import { descriptionSelector } from "_store/selected/Description/selectors";
import { isIterable } from "__utilities/Public";
import { cloneOptions, sortOptions } from "__utilities/functions/Public";

export default function OptionSummary({ width }) {
  const [{ isLoading }, dispatchSetOptions] = useRecoilState(optionsSelector);

  const dispatchSetDescription = useSetRecoilState(descriptionSelector);

  const summary = useRecoilValue(summarySelector);
  const isLoadingSummary = summary.isLoading;
  const cabinet = summary.cabinet;

  let selectedOptions = [];

  const globalOptions = isIterable(cabinet?.globalOptions?.options)
    ? cabinet?.globalOptions?.options
    : [];
  const comparment1 = isIterable(cabinet?.comparment1?.fullSize.options)
    ? cabinet?.comparment1?.fullSize.options
    : [];
  const comparment2 = isIterable(cabinet?.comparment2?.fullSize.options)
    ? cabinet?.comparment2?.fullSize.options
    : [];
  const compartment1mM300 = isIterable(cabinet?.comparment1?.mM300.options)
    ? cabinet?.comparment1?.mM300.options
    : [];
  const compartment2mM300 = isIterable(cabinet?.comparment2?.mM300.options)
    ? cabinet?.comparment2?.mM300.options
    : [];

  function uniqueOptions(array) {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i]?.id === a[j]?.id) {
          a[i].quantity = (a[i].quantity ?? 0) + (a[j]?.quantity ?? 0);
          a[i].chargableQuantity =
            (a[i].chargableQuantity ?? 0) + (a[j]?.chargableQuantity ?? 0);
          a.splice(j--, 1);
        }
      }
    }

    return a;
  }

  if (cabinet !== null) {
    selectedOptions = uniqueOptions(
      cloneOptions(globalOptions)
        .concat(cloneOptions(comparment1))
        .concat(cloneOptions(comparment2))
        .concat(cloneOptions(compartment1mM300))
        .concat(cloneOptions(compartment2mM300))
    )?.sort(sortOptions);
  }

  return (
    <div
      style={{ width }}
      className={
        "option-summary-container" +
        (isLoading || isLoadingSummary ? " lock" : "")
      }
    >
      <Fragment key="selectedOptions">
        {selectedOptions.map((option) => (
          <Fragment key={`summary-option-${option.id}`}>
            <OptionSummaryElement
              key={option.id}
              id={option.id}
              name={option.title}
              type={option.type}
              image={option.imageUrl}
              value={option.value}
              minValue={option.minValue}
              maxValue={option.maxValue}
              price={option.price}
              isLocked={option.isLocked}
              isDisabled={option.isDisabled}
              disableReason={option.disableReason}
              isAvailable={option.isAvailable}
              canDelete={option.canDelete}
              quantity={option.quantity}
              occupiedSpace={option.occupiedSpace}
              chargableQuantity={option.chargableQuantity}
              onChange={(quantity) =>
                quantity
                  ? selectManyOption(
                      { ...option, quantity },
                      dispatchSetOptions,
                      dispatchSetDescription
                    )
                  : deselectOption(
                      option,
                      dispatchSetOptions,
                      dispatchSetDescription
                    )
              }
            />
          </Fragment>
        ))}
      </Fragment>
    </div>
  );
}
