import ReactSlider from "react-slider";

const FilterSlider = ({ start, end, min, max, onSliderChangeHandler, title, type}) => {
  return (
    <div className="horizontal-slider-container">
      <div className="horizontal-slider-title">{title}</div>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        onAfterChange={onSliderChangeHandler}
        value={[start, end]}
        min={min}
        max={max}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}{type&&type==="temp"&&"°C"}</div>}
      />
    </div>
  );
};
export default FilterSlider;
