import { atom } from "recoil";

const state = atom({
  key: "store.userData.sending.state",
  default: {
    isSending: false,
  },
});

export default state;
