import React from "react";
import InputField from "./InputField/InputField";

export default function PhoneInput({
  label,
  phoneCode,
  customNumber,
  onChange,
  isInvalid,
  invalidText,
}) {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "flex-end",
      }}
    >
      {!customNumber && (
        <InputField
          label={label}
          value={phoneCode}
          disabled={true}
          style={{ width: "30%" }}
          inputStyle={{opacity: "0.6"}}
        />
      )}

      <InputField
        isInvalid={isInvalid}
        invalidText={invalidText}
        label={customNumber && label}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}
