import { v4 as uuid } from "uuid";

export const arrayToMatrix = (marixRowLimit, array) => {
  let index = 0;
  let matrix = [];
  let slice = [];

  array.forEach((i) => {
    slice.push(i);
    if (index === marixRowLimit - 1) {
      matrix.push(slice);
      slice = [];
      index = 0;
    }

    index++;
  });

  return matrix;
};

export const getUuid = () => {
  return uuid();
};
export const validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const restartMainScrollBar = () => {
  window.scrollTo(0, 0);
};

export const addSignAccordingToNumber = (number) => {
  try {
    if (number >= 0) {
      return "+ " + number;
    }
    if (number < 0) {
      let array = String(number).split("");
      let numerPart = "";
      for (let i = 1; i < array.length; i++) {
        numerPart += array[i];
      }
      return array[0] + " " + numerPart;
    }
  } catch (ex) {
    console.log(ex);
  }
};

export const sortOptions = (option1, option2) => {
  if ((option1?.bioCodeOrder ?? 0) === (option2?.bioCodeOrder ?? 0)) {
    return 0;
  }

  return (option1?.bioCodeOrder ?? 0) < (option2?.bioCodeOrder ?? 0) ? -1 : 1;
};

export const cloneOptions = (options) => {
  if (!options) return 
  return JSON.parse(JSON.stringify(options));
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
};

export const priceFormater = (
  amount,
  currency,
  localizationISOCode,
  currencyPosition
) => {
  const formated = parseFloat(amount).toLocaleString(localizationISOCode);

  return currencyPosition === "Right"
    ? (formated ?? "") + " " + (currency ?? "")
    : (currency ?? "") + " " + (formated ?? "");
};

export const addSignToFormatedPrice = (
  amount,
  currency,
  localizationISOCode,
  currencyPosition
) => {
  const sign = amount >= 0 ? "+" : "-";

  let chackedAmount = amount
  if (amount < 0) {
    let array = String(amount).split("");
    let numerPart = "";
    for (let i = 1; i < array.length; i++) {
      numerPart += array[i];
    }
    chackedAmount = numerPart;
  }

  const formatedWithCurrency = priceFormater(
    chackedAmount,
    currency,
    localizationISOCode,
    currencyPosition
  );

  return sign + formatedWithCurrency;
};

export const removeFromURL = (substring) => {
  window.history.replaceState({}, document.title, window.location.href.replace(substring, ''));
}

export const getCommaSeparatedStringFromArray = (array) => array.join(',');