import React from "react";
import "./Popup.css";

export default function Popup({ children, style, onClose }) {
  return (
    <div className="popup-overlay">
      <div onClick={onClose ? onClose : () => {}} style={{height: "100%", width: "100%", position: "absolute"}}></div>
      <div className="popup-container" style={{ ...style }}>
        {onClose && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <div onClick={onClose} className="popup-xicon"></div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
