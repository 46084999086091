import ApiClient from "_config/client";
import types from "_store/types";

const base = "/dimension";

export const getDimensionsRequestBuilder = () => ApiClient.get(base);

export const selectDimensionRequestBuilder = (id) =>
  ApiClient.post(`${base}/${id}/select`);

export const getDimensins = (dispatch) => {
  dispatch({ type: types.started });

  getDimensionsRequestBuilder()
    .then(({ data }) => dispatch({ type: types.success, payload: data }))
    .catch((error) => dispatch({ type: types.error }));
};

const dispatchMutatingAction = (request, dispatch) => {
  dispatch({ type: types.started });

  request
    .then(() => getDimensins(dispatch))
    .catch((error) => dispatch({ type: types.error }));
};

export const selectDimension = ({ id }, dispatch) => {
  dispatchMutatingAction(selectDimensionRequestBuilder(id), dispatch);
};
