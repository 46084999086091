import Popup from "components/Popup/Popup";
import ContactSection from "features/contact/ContactSection";
import VerticalProgressStepBar from "features/progress/VerticalProgressStepBar";
import BioCode from "features/selection/BioCode/BioCode";
import CompartmentIndicatorContainer from "features/selection/CompartmentIndicator/CompartmentIndicatorContainer";
import DimensionsIndicator from "features/selection/DimensionsIndicator/DimensionsIndicator";
import { useRecoilValue } from "recoil";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import { summarySelector } from "_store/summary/selectors";
import { summaryPage } from "__utilities/PageStepEnum";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import CustomButton from "components/Buttons/CustomButton";

export default function YourConfigurationPopup({ onClose, setDiscardPopup }) {
  const getLocalizationText = useGetLocalizationText();
  const { currPage } = useActivePage();

  const isSmallScreen = useIsSmallScreen();

  const summary = useRecoilValue(summarySelector);
  const showCompartmentIndicator =
    summary?.cabinet?.comparment1?.fullSize?.spaceTotal > 0;

  return (
    <Popup
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        alignItems: "self-start",
        justifyContent: "start",
        padding: "10px",
        background: "var(--primarySuperLightGray)",
        overflow: "auto",
        overflowX: "hidden",
        maxHeight: "90%",
      }}
      onClose={onClose}
    >
      <VerticalProgressStepBar activePage={currPage} />
      <BioCode title={getLocalizationText("Summary_BioCodeUltra_Title")} />
      <DimensionsIndicator style={{ width: "100%" }} />
      {showCompartmentIndicator && (
        <CompartmentIndicatorContainer
          disabled={currPage >= summaryPage ? true : false}
          style={{ marginBottom: "20px" }}
        />
      )}
      <ContactSection
        style={{ width: "100%", display: "flex", alignItems: "center" }}
      />
      {isSmallScreen && (
        <CustomButton
          text={getLocalizationText("Global_Button_Discard")}
          type="discard"
          onClick={() => {
            onClose();
            setDiscardPopup(true);
          }}
        />
      )}
    </Popup>
  );
}
