import "./Switch.css";

const Switch = ({ checked, onClick }) => {
  return (
    <label className="switch" onClick={() => onClick()}>
      <input type="checkbox" defaultChecked={checked} />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
