import React, { memo } from "react";
import { useRecoilValue } from "recoil";
import { useActivePage } from "_store/page/selectors";
import { dimensionSelector } from "_store/products/dimensions/selectors";
import { energyTypeSelector } from "_store/products/energyTypes/selectors";
import { materialSelector } from "_store/products/material/selectors";
import { optionsSelector } from "_store/products/options/selectors";
import {
  bioCodeFormater,
  summarySelector,
  useRefreshSummary,
} from "_store/summary/selectors";
import { getUuid } from "__utilities/functions/Public";
import useDidMountEffect from "__utilities/hooks/useDidMountEffect";
import { emptySigne } from "__utilities/Public";
import "./BioCode.css";
import BioCodeCell from "./BioCodeCell";

export const ReturnCells = (bioCodes) => {
  return (
    <div className="bio-code-cell-wrapper">
      {bioCodes.map((_, index) => (
        <div key={getUuid()} className="bio-code-cell-container">
          {bioCodes[index].map((element) => {
            if (element === emptySigne) {
              return <BioCodeCell key={getUuid()} />;
            }
            return <BioCodeCell key={getUuid()} active={true} data={element} />;
          })}
        </div>
      ))}
    </div>
  );
};

const BioCode = ({ title }) => {
  const summary = useRecoilValue(summarySelector);
  //const isLoading = summary.isLoading;
  const bioCodes = bioCodeFormater(summary.bioCode);
  // const { currPage } = useActivePage();
  // const refreshSummary = useRefreshSummary();

  // const { options } = useRecoilValue(optionsSelector);
  // const { dimensions } = useRecoilValue(dimensionSelector);
  // const { materials } = useRecoilValue(materialSelector);
  // const { energyTypes } = useRecoilValue(energyTypeSelector);

  // useDidMountEffect(() => {
  //   refreshSummary(currPage < 10 ? currPage : 10);
  // }, [dimensions, materials, energyTypes, options]);

  return (
    <div className="bio-code-container">
      <div className="bio-code-container-title">{title}</div>
      {ReturnCells(bioCodes)}
    </div>
  );
};

export default memo(BioCode);
