export const LOADING_IDLE = 'LOADING_IDLE'
export const LOADING_STARTED = 'LOADING_STARTED'
export const LOADING_SUCCESS = 'LOADING_SUCCESS'
export const LOADING_ERROR = 'LOADING_ERROR'

const bundle = {
    idle: LOADING_IDLE,
    started: LOADING_STARTED,
    success: LOADING_SUCCESS,
    error: LOADING_ERROR
}
export default bundle