import InformingTypeElement from "features/informingType/InformingTypeElement";
import { useRecoilState } from "recoil";
import "./ChooseInformingTypePage.css";
import { informingTypeSelector } from "_store/informingType/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";

const ChooseInformingTypePage = () => {
  const getLocalizationText = useGetLocalizationText();
  const [{ informingType }, setInformingType] = useRecoilState(
    informingTypeSelector
  );
  return (
    <div className="choose-informing-type-container">
      <div className="choose-informing-type-header">
      {getLocalizationText("ChooseInformType_Title")}
      </div>
      <div className="choose-informing-type-main-container">
        <InformingTypeElement
          isSelected={informingType === "createConfigurationID" && true}
          onClick={() => {
            setInformingType("createConfigurationID");
          }}
        >
          {getLocalizationText("ChooseInformType_CreateConfigID_Text")}
        </InformingTypeElement>
        <InformingTypeElement
          isSelected={informingType === "quotation" && true}
          onClick={() => {
            setInformingType("quotation");
          }}
        >
          {getLocalizationText("ChooseInformType_SendQuotation_Text")}
        </InformingTypeElement>
        <InformingTypeElement
          isSelected={informingType === "contactMe" && true}
          onClick={() => {
            setInformingType("contactMe");
          }}
        >
          {getLocalizationText("ChooseInformType_ContactMe_Text")}
        </InformingTypeElement>
      </div>
    </div>
  );
};

export default ChooseInformingTypePage;
