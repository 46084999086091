import { atom } from "recoil";

const state = atom({
  key: "store.selected.description.state",
  default: {
    isLoading: false,
    type:null,
    description:{},  
  },
});

export default state;
