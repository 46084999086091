import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import App from "./App";
import { RecoilRoot } from "recoil";
import { ViewportProvider } from "_context/ViewportProvider";
import "./Global.css"

import "./_resources/fonts/HelveticaNeue-Roman.css"
import "./_resources/fonts/HelveticaNeue-Bold.css"
import "./_resources/fonts/HelveticaNeue-Medium.css"
import "./_resources/fonts/HelveticaNeue-Light.css"

ReactDOM.render(
 <div className="product-configurator">
   <React.StrictMode>
    <ViewportProvider>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </ViewportProvider>
  </React.StrictMode>
 </div>,
  document.getElementById("root")
);
