import { useRecoilState, useRecoilValue } from "recoil";
import { compartmentSelector } from "_store/selected/compartment/selectors";
import { summarySelector } from "_store/summary/selectors";
import CompartmentIndicator from "./CompartmentIndicator";
import "./CompartmentIndicatorContainer.css";
import { useEffect } from "react";

const typeEnum = {
  DoubleDoor: " double",
  SingleDoor: " single",
  Combination: "",
};
const getTypeString = (type) => {
  return typeEnum[type] ? typeEnum[type] : "";
};
const getGetCabinetSizeClass = (maxSize) => {
  let result = "";
  if (maxSize > 20) {
    result = "large";
  }
  if (maxSize < 20) {
    result = "medium";
  }
  if (maxSize < 10) {
    result = "small";
  }
  return result;
};

const CompartmentIndicatorContainer = ({ disabled, style }) => {
  let [selectedCompartment, setSelectedCompartment] =
    useRecoilState(compartmentSelector);
  selectedCompartment = disabled === true ? "" : selectedCompartment;

  const state = useRecoilValue(summarySelector);

  const cabinetType = state.cabinet?.cabinetType;
  const comp1 = state.cabinet?.comparment1?.fullSize?.options;
  const comp1SpaceOccupied =
    state.cabinet?.comparment1?.fullSize?.spaceOccupied;
  const comp1SpaceTotal = state.cabinet?.comparment1?.fullSize?.spaceTotal;
  const comp1mm300 = state.cabinet?.comparment1?.mM300?.options;
  const comp1mm300SpaceOccupied =
    state.cabinet?.comparment1?.mM300?.spaceOccupied;
  const comp1mm300SpaceTotal = state.cabinet?.comparment1?.mM300?.spaceTotal;

  const comp2 = state.cabinet?.comparment2?.fullSize?.options;
  const comp2SpaceTotal = state.cabinet?.comparment2?.fullSize?.spaceTotal;
  const comp2SpaceOccupied =
    state.cabinet?.comparment2?.fullSize?.spaceOccupied;
  const comp2mm300 = state.cabinet?.comparment2?.mM300?.options;
  const comp2mm300SpaceOccupied =
    state.cabinet?.comparment2?.mM300?.spaceOccupied;
  const comp2mm300SpaceTotal = state.cabinet?.comparment2?.mM300?.spaceTotal;

  useEffect(() => {
    setSelectedCompartment("Compartment1");
  }, [])

  return (
    <div
      className={
        "compartment-indicator-container-wrapper" + getTypeString(cabinetType)
      }
      style={{ ...style }}
    >
      <CompartmentIndicator
        key={"Compartment1"}
        id={"Compartment1"}
        options={comp1}
        mm300Options={comp1mm300}
        spaceOccupied={comp1SpaceOccupied}
        mm300spaceOccupied={comp1mm300SpaceOccupied}
        spaceTotal={comp1SpaceTotal}
        mm300SpaceTotal={comp1mm300SpaceTotal}
        isSelected={selectedCompartment === "Compartment1"}
        type={getGetCabinetSizeClass(comp1SpaceTotal)}
        onClickHandler={(id) => {
          setSelectedCompartment(id);
        }}
      />
      {(cabinetType === "DoubleDoor" || cabinetType === "Combination") && (
        <CompartmentIndicator
          key={"Compartment2"}
          id={"Compartment2"}
          options={comp2}
          mm300Options={comp2mm300}
          spaceOccupied={comp2SpaceOccupied}
          mm300spaceOccupied={comp2mm300SpaceOccupied}
          spaceTotal={comp2SpaceTotal}
          mm300SpaceTotal={comp2mm300SpaceTotal}
          isSelected={selectedCompartment === "Compartment2"}
          type={getGetCabinetSizeClass(comp2SpaceTotal)}
          onClickHandler={(id) => {
            setSelectedCompartment(id);
          }}
        />
      )}
    </div>
  );
};

export default CompartmentIndicatorContainer;
