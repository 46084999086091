import { useEffect } from "react";

export default function useOnOutsideClick(ref, onOutsideClick, depArray = []) {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [...depArray]);
}
