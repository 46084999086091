import { atom } from "recoil";

const state = atom({
  key: "store.session.state",
  default: {
    region: "DK",
    regions: [],
    isDistributor: false,
    sortOptions: []
  },
});

export default state;