import Popup from "components/Popup/Popup";
import DescriptionWidget from "features/product/DescriptionWidget";

export default function AboutRangePopup({ onClose }) {
  return (
    <Popup
      style={{
        display: "flex",
        flexDirection: "column",
        width: "75%",
        borderRadius: "0px",
        padding: "10px",
        background: "var(--primarySuperLightGray)",
        maxHeight: "90%"
      }}
      onClose={onClose}
    >
      <DescriptionWidget style={{overflow: "scroll"}}/>
    </Popup>
  );
}
