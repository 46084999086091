import "./Product.css";
import CustomButton from "components/Buttons/CustomButton";
import { useGetLocalizationText } from "_store/localization/selectors";
import { getUuid, priceFormater } from "__utilities/functions/Public";
import CardOverlay from "./disableReason/CardOverlay";
import { Fragment, useState } from "react";
import { useGetSelectedRegionObject } from "_store/session/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import CardSeparator from "components/CardSeparator/CardSeparator";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";

const Product = ({
  id,
  name,
  details,
  image,
  price,
  configurateSelected,
  userConfirmationRequired,
  readMoreUrl,
}) => {
  const getLocalizationText = useGetLocalizationText();

  const [isPopUpShown, setIsPopUpShown] = useState(false);

  const regionObj = useGetSelectedRegionObject();

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();
  
  const onClickConfigurateHandler = () => {
    configurateSelected(id);
  };

  return (
    <div
      className={
        "product-card-info-container" +
        (isPopUpShown ? " block" : "") +
        (isSmallScreen || isMediumScreen ? " smallScreen" : "")
      }
    >
      {isPopUpShown ? (
        <CardOverlay
          text={getLocalizationText("Step1_UserConfirmation_ChangeModel")}
          type={"confirmation"}
          action={(state) => {
            if (state === "yes") {
              onClickConfigurateHandler();
            }
            if (state === "no") {
              setIsPopUpShown(false);
            }
          }}
        />
      ) : (
        <Fragment>
          <div className="product-card-image-container">
            <img className="product-card-image " alt="" src={image} />
          </div>
          <div className="product-card-text-container">
            <div className={"product-card-text-title" +  (isSmallScreen || isMediumScreen ? " smallScreen" : "")}>{name}</div>
            {details.map((element) => (
              <div key={getUuid()} className="product-card-configuration">
                <label>{element.name}:</label>
                <div>
                  {element.values.map((value) => (
                    <div key={getUuid()}>{value}</div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className="configurate-button">
            <div className="description-widget-link-container">
              <a href={readMoreUrl} target="_blank" rel="noreferrer">
                {getLocalizationText("Global_Button_ReadSpec")}
              </a>
            </div>
            {isSmallScreen && <CardSeparator />}
            <div className="product-card-price">
              {getLocalizationText("CabinetDetails_From_Label")}{" "}
              {priceFormater(
                price.amount,
                price.currency,
                regionObj?.localizationISOCode,
                regionObj?.currencyPosition
              )}
            </div>
            <CustomButton
              onClick={
                userConfirmationRequired
                  ? () => setIsPopUpShown(true)
                  : onClickConfigurateHandler
              }
              text={getLocalizationText("Global_Button_Configure")}
              type="cabinet-selection"
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Product;
