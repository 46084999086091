import { useRef, useState, useEffect, memo } from "react";
import "./Counter.css";

const Counter = ({ value, minValue, maxValue, onChange, type, isSelected, setCountRef, setInputRef, rotated, style, hasInput, delayAmount = 400, onPlusButtonMouseOver}) => {
  const [input, setInput] = useState(value);
  const [count, setCount] = useState(value);
  const ref = useRef();

  useEffect(() => {
    if (setCountRef?.current) {
      setCountRef.current = setCount
    }
    if (setInputRef?.current) {
      setInputRef.current = setInput
    }
  }, [])

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }
    let timer = setTimeout(() => onChange(count), delayAmount);
    return () => clearTimeout(timer);
  }, [count]);

  const onInputChange = (e) => {
    const value = Math.max(minValue, Math.min(maxValue, Number(e.target.value)));
    setInput(value)
  }

  const onInputFocus = (e) => {
    e.target.select();
  }

  const onInputBlur = () => {
    if(input === value) return
    onChange(input)
  }

  return (
    <div className={"counter-container" + (type ? type : "") + (rotated ? " rotated" : "")} style={{...style}}>
      <button
        className={
          "counter-button" +
          (type ? type : "") +
          (isSelected ? " selected" : "")+
          (minValue != null && count - 1 < minValue? " disabled" : "")
        }
        onClick={(e) => {
          e.stopPropagation();
          if (count - 1 < minValue) return;
          setCount(count - 1);
          setInput(count - 1);
        }}
      >
         <span className="counter-button-minus-icon"></span>
      </button>

      {hasInput ? 
      <input className={
        "counter-input" +
        (isSelected ? " selected" : "")
      } type="number" value={input} min={minValue} max={maxValue} onInput={onInputChange} onFocus={onInputFocus} onBlur={onInputBlur} /> : 
      <div className={"counter-value" + (type ? type : "") + (rotated ? " rotated" : "")}>{count}</div>}
      
      <button
        className={
          "counter-button" +
          (type ? type : "") +
          (isSelected ? " selected" : "") +
          (maxValue != null && count + 1 > maxValue ? " disabled" : "")
        }
        onClick={(e) => {
          e.stopPropagation();
          if (maxValue != null) {
            if (count + 1 > maxValue) return;
          }
          setCount(count + 1);
          setInput(count + 1)
        }}
        onMouseOver={onPlusButtonMouseOver}
      >
        <span className="counter-button-plus-icon"></span>
      </button>
    </div>
  );
};

export default memo(Counter);
