import ApiClient from "_config/client";

const base = "/description";

export const getRangeDescriptionRequest = () =>
  ApiClient.get(base + "/range");

export const getModelDescriptionRequest  = () =>
  ApiClient.get(base + "/model");

