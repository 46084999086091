import ApiClient from "_config/client";
import types from "_store/types";

const base = "/energyType";

export const getEnergyTypeRequestBuilder = () => ApiClient.get(base);

export const selectEnergyTypeRequestBuilder = (id) =>
  ApiClient.post(`${base}/${id}/select`);

export const getEnergyTypes = (dispatch) => {
  dispatch({ type: types.started });
  getEnergyTypeRequestBuilder()
    .then(({ data }) => dispatch({ type: types.success, payload: data }))
    .catch((error) => dispatch({ type: types.error }));
};

const dispatchMutatingAction = (request, dispatch) => {
  dispatch({ type: types.started });

  request
    .then(() => getEnergyTypes(dispatch))
    .catch((error) => dispatch({ type: types.error }));
};

export const selectEnergyType = ({ id }, dispatch) =>
  dispatchMutatingAction(selectEnergyTypeRequestBuilder(id), dispatch);
