import state from "./index";
import { selector, useRecoilState, useSetRecoilState } from "recoil";

export const sectionsStateSelector = selector({
  key: "store.page.sections.selector",
  get: ({ get }) => get(state).state,
  set: ({ get, set }, newValue) => {
    set(state, {
      ...get(state),
      state: newValue,
    });
  },
});

export const useClosedSections = () => {
  const [sections, setSections] = useRecoilState(sectionsStateSelector);

  return {
    setClosed: (section) => {
      if (sections.includes(section)) {
        sections.filter((s) => s === section);
        setSections(sections.filter((s) => s !== section));
      }
      if (!sections.includes(section)) {
        setSections([...sections, section]);
      }
    },
    getClosed: (section) => {
      if (sections.includes(section)) {
        return false;
      }
      return null;
    },
  };
};

export const useRefreshClosedSections = () => {
  const set = useSetRecoilState(sectionsStateSelector);

  return () => {
    set([]);
  };
};
