
export default function CardSeparator() {
  return (
    <div
      style={{
        height: "1px",
        width: "100%",
        background: "var(--thirdGray)",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    />
  );
}
