import React from "react";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import "./EstimateOverviewCard.css"

export default function EstimateOverviewCard({ children }) {
  const isSmallScreen = useIsSmallScreen()
  return (
    <div className={"estimate-overview-element-card-container" + (isSmallScreen ? " smallScreen": "")}>{children}</div>
  );
}
