import { atom } from "recoil";

const state = atom({
  key: "store.selected.compartment.state",
  default: {
    compartment:"Compartment1",
  },
});

export default state;
