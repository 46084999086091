export const emptySigne = "empty";

export const bioCodeRowCells = 5;

export function isIterable(obj) {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

export const adaptToSendingType = (request, model, dispatch) => {//for user informations
  request(model)
    .then(() => {
      dispatch({ type: "confirmation" });
    })
    .catch((error) => {
      console.log(error);
    });
};
