import React, { useEffect, useState } from "react";
import "./RegionSelector.css";
import RegionSelectorElement from "components/RegionSelectorElement/RegionSelectorElement";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  sessionRegionSelector,
  sessionRegionsSelector,
} from "_store/session/selectors";

export default function RegionSelector() {
  const regions = useRecoilValue(sessionRegionsSelector);
  const [regionState, setRegionState] = useRecoilState(sessionRegionSelector);

  const isSmallScreen = useIsSmallScreen();
  const isMediumScreen = useIsMediumScreen();

  const selectRegion = (selectedRegion) => {
    if (!selectedRegion) return;

    setRegionState(selectedRegion);
  };

  return (
    <div
      className={`region-selector-container ${
        isMediumScreen ? "mediumScreen" : ""
      }${isSmallScreen ? "smallScreen" : ""}`}
    >
      {regions?.map((region) => (
        <RegionSelectorElement
          name={region.name}
          currency={region.currency}
          image={region.imageSrc}
          isSelected={region?.code === regionState?.code}
          onClick={() => selectRegion(region)}
        />
      ))}
    </div>
  );
}
