import {
  chooseRangePage,
  chooseRegionPage,
  confirmationPage,
  estimatePage,
} from "__utilities/PageStepEnum";
import {
  deleteDistributorHeader,
  getBioCodeFromURL,
  getDistributorFromURL,
  getPageIndexFromStorage,
  getRegion,
  getRegionFromURL,
  isFirstStartUpHappened,
  setAcquireQuotationRequestMessage,
  setDistributorHeader,
  setLanguageHeader,
  setRegion,
  urlSessionIdCheck,
  urlSessionIdIsValid,
} from "_config/session";
import {
  createNewConfigurationFromBiocode,
  getConfigurationRegionRequest,
  newConfiguration,
  validateBiocode,
} from "_store/configurations/service";
import {
  convertLocalization,
  localizationSelector,
} from "_store/localization/selectors";
import { getLocalization } from "_store/localization/service";
import { useActivePage } from "_store/page/selectors";
import { dimensionSelector } from "_store/products/dimensions/selectors";
import { energyTypeSelector } from "_store/products/energyTypes/selectors";
import { materialSelector } from "_store/products/material/selectors";
import { optionsSelector } from "_store/products/options/selectors";
import {
  sessionIsDistributorSelector,
  sessionRegionSelector,
  sessionRegionsSelector,
} from "_store/session/selectors";
import {
  getRegionsRequest,
  getSessionRegion,
  requestOfficialQuotationRequest,
  validateDistributor,
} from "_store/session/service";
import { useRefreshSummary } from "_store/summary/selectors";
import { useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useDidMountEffect from "./useDidMountEffect";
import { removeFromURL } from "__utilities/functions/Public";

export const useOnAppGlobalLoadEffect = (
  setCanRender,
  setDiscardPopup,
  setDiscardPopupDispatch,
  setDiscardPopupOnCloseDispatch,
  setInvalidBiocodePopup,
  setIsInvalidDistributorCode
) => {
  const { currPage, setPage } = useActivePage();
  const setRegionState = useSetRecoilState(sessionRegionSelector);
  const setRegions = useSetRecoilState(sessionRegionsSelector);
  const refreshSummary = useRefreshSummary();
  const setLocalization = useSetRecoilState(localizationSelector);

  const setIsDistributor = useSetRecoilState(sessionIsDistributorSelector);

  const { options } = useRecoilValue(optionsSelector);
  const { dimensions } = useRecoilValue(dimensionSelector);
  const { materials } = useRecoilValue(materialSelector);
  const { energyTypes } = useRecoilValue(energyTypeSelector);

  useDidMountEffect(() => {
    // refresh summary on page change
    refreshSummary(currPage < 10 ? currPage : 10);
  }, [dimensions, materials, energyTypes, options]);

  useEffect(() => {
    //Main Load Effect
    const pageIndex = getPageIndexFromStorage();
    const urlSessionIdValidation = urlSessionIdIsValid();
    urlSessionIdCheck(
      urlSessionIdValidation,
      () => setPage(estimatePage),
      () => {
        requestOfficialQuotationRequest()
          .then(() => {
            setAcquireQuotationRequestMessage("Success");
            setPage(confirmationPage);
          })
          .catch((ex) => {
            console.log(ex);
            setAcquireQuotationRequestMessage("Error");
            setPage(confirmationPage);
          });
      }
    );

    //setRegionState(getRegion());

    if (pageIndex === "0" && !getRegion()) {
      const region = { code: getRegionFromURL() };

      setRegionState(region);
    }

    onStartUpLoad(urlSessionIdValidation, pageIndex);
  }, []);

  const checkIfRegionIsValid = async (isValidDistributorId) => {
    const { success, error, data } = await getSessionRegion();
    
    if (!success) {
      console.log(error);
    }

    if (getRegion() !== data.code) {
      if (isValidDistributorId) {
        setIsInvalidDistributorCode(true);
      } else {
        setRegion(null);
        return;
      }
    }
  };

  const getCurrRegionCallBack = (isValidDistributorId) =>
    getConfigurationRegionRequest()
      .then(({ data }) => {
        if (currPage === 1) {
          setRegionState(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  const setupLocalization = () => {
    getLocalization().then((data) => {
      setLocalization(convertLocalization(data.data));
      setCanRender(true);
    });
  };

  const loadRegionFromUrlOnFirstLoad = (regions, isUrlSessionIdValid) => {
    const regionFromLocalStorage = getRegion();

    let region = regionFromLocalStorage ?? { code: getRegionFromURL() };

    if (regionFromLocalStorage) {
      region = regions.find((item) => item.code === regionFromLocalStorage);
    }

    regionFromLocalStorage && !isUrlSessionIdValid && setPage(1);

    setRegionState(region);
  };

  const distributorValidation = async () => {
    const distributor = getDistributorFromURL();
    if (!distributor) return false;
    setDistributorHeader(distributor);
    const { success, error, data } = await validateDistributor();

    if (success) {
      setupDistributor(data);
      return true;
    } else {
      deleteDistributorHeader();
      setIsInvalidDistributorCode(true);
      setIsDistributor(false);
      return false;
    }
  };

  const setupDistributor = (data) => {
    setRegion(data.region);
    setRegionState(data.region);
    setLanguageHeader(data.language);
    setIsDistributor(true);
  };

  const onSuccessConfigurationCreationFromBiocode = (biocode, dispatch) => {
    refreshSummary(2, (steps) => {
      const firstNotCompletedStep = steps.find((step) => !step.isCompleted);

      if (firstNotCompletedStep.number <= 4) {
        setPage(firstNotCompletedStep.number);
      } else {
        const firstAvailableStep = steps.find((step) => {
          if (step.number > 4) {
            return step.isAvailable;
          }
        });

        if (firstAvailableStep) {
          setPage(firstAvailableStep.number);
        }
      }
      removeFromURL(`?biocode=${biocode}`);
      if (dispatch) {
        dispatch();
      }
    });
  };

  const checkIfURLContainsBiocode = async (firstStartUp, setupLocalization) => {
    let urlContainsValidBiocode = false;
    let biocode = getBioCodeFromURL();

    if (biocode) {
      urlContainsValidBiocode = await validateBiocode(biocode);
      if (urlContainsValidBiocode) {
        const pageIndex = getPageIndexFromStorage();

        if (firstStartUp || pageIndex == chooseRangePage) {
          createNewConfigurationFromBiocode(biocode).then((success) => {
            if (success) {
              onSuccessConfigurationCreationFromBiocode(
                biocode,
                setupLocalization
              );
            } else {
              setupLocalization();
            }
          });
        } else {
          setDiscardPopupDispatch((prev) => () => {
            createNewConfigurationFromBiocode(biocode).then((success) => {
              if (success) {
                onSuccessConfigurationCreationFromBiocode(biocode);
              }
            });
          });
          setDiscardPopupOnCloseDispatch((prev) => () => {
            removeFromURL(`?biocode=${biocode}`);
          });
          setDiscardPopup(true);
          setupLocalization();
        }
      } else {
        setupLocalization();
        setInvalidBiocodePopup(true);
      }
    } else {
      setupLocalization();
    }
  };

  const onStartUpLoad = async (urlSessionIdValidation, pageIndex) => {
    const data = await getRegionsRequest();

    setRegions(data.data);

    const firstStartUp = isFirstStartUpHappened();
    const isValidDistributorId = await distributorValidation();

    if (firstStartUp) {
      await checkIfRegionIsValid(isValidDistributorId);

      // load region from url on first startup
      loadRegionFromUrlOnFirstLoad(data.data, urlSessionIdValidation.isValid);
    } else {
      const region = data.data.find((item) => item.code === getRegion()) ?? {
        code: getRegionFromURL(),
      };
      setRegionState(region);
    }

    if (getRegion()) {
      checkIfURLContainsBiocode(firstStartUp, setupLocalization);
    } else {
      setupLocalization();
    }

    const isNewConfig = firstStartUp && !urlSessionIdValidation.isValid;

    if (isNewConfig) {
      newConfiguration(() => {
        refreshSummary(currPage);
      });
    } else {
      getCurrRegionCallBack(isValidDistributorId);
    }

    if (pageIndex) {
      if (!urlSessionIdValidation.isValid) {
        setPage(parseInt(pageIndex));
        isValidDistributorId && currPage == chooseRegionPage && setPage(1); //skipping chooseRegionPage with Distributor
      }
    }
  };
};
