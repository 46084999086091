import axios from "axios";
import { addHeaders } from "./session";

const client = axios.create({
  // baseURL: "https://localhost:44369/api/v1/configurator",
  // baseURL: "https://biolineconfiguratorwebapi.azurewebsites.net/api/v1/configurator",
  baseURL: "https://app-bio-configurator-api-prod-001.azurewebsites.net/api/v1/configurator",
});
export default client


client.interceptors.request.use(
  config => addHeaders(config)
)

export const updateAxiosInstanceHeader = (headerName, headerValue) => {
  const currentHeaders = {...client.defaults.headers};

  currentHeaders[headerName] = headerValue;

  client.defaults.headers = currentHeaders;
};

export const deleteAxiosInstanceHeader = (headerName) => {
  const currentHeaders = {...client.defaults.headers};

  delete currentHeaders[headerName]

  client.defaults.headers = currentHeaders;
}