import { selector } from "recoil";
import state from "./index";

export const rangeSelector = selector({
  key: "store.selected.range.selector",
  get: ({ get }) => get(state).range,
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      range: newValue,
    }),
});
