import { selector, useSetRecoilState } from "recoil";
import { getCountryRequest } from "_store/userData/service";
import state from "./index";

export const countriesSelector = selector({
  key: "store.countries.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, list) =>
    set(state, {
      ...get(state),
      list,
    }),
});

export const useGetCountries = () => {
  const setCountries = useSetRecoilState(countriesSelector);

  return (dispatch) => {
    getCountryRequest()
      .then((data) => {
        setCountries(data.data);
        dispatch(data.data)
      })
      .catch((error) => {
        console.log(error);
        dispatch()
      });
  };
};