import { useRecoilValue } from "recoil";
import {
  advancedFilterIsLoadingSelector,
  advancedFilterSelector,
  useAdvancedFilter,
} from "_store/filter/selectors";
import "./AdvancedFilter.css";
import { useRef, useState } from "react";
import useOnOutsideClick from "__utilities/hooks/useOnOutsideClick";
import { useGetLocalizationText } from "_store/localization/selectors";
import Section from "components/Section";
import AdvancedFilterElement from "./AdvancedFilterElement";
import AdvancedFilterElementImage from "./AdvancedFilterElementImage";

export default function AdvancedFilter({ onFilterChange, freez }) {
  const advancedFilter = useRecoilValue(advancedFilterSelector);
  const advancedFilterIsLoading = useRecoilValue(
    advancedFilterIsLoadingSelector
  );

  const { onItemChangeHandler, clearFilterCategory } = useAdvancedFilter();

  const ref = useRef(null);
  const containerRef = useRef(null);

  const [showFilter, setShowFilter] = useState(false);

  const onFilterChangeHandler = (tag) => {
    const newFilter = onItemChangeHandler(tag);
    onFilterChange(newFilter);
  };

  const onClearCategoryFilterHandler = (title) => {
    const newFilter = clearFilterCategory(title);
    onFilterChange(newFilter);
  }

  const getLocalizationText = useGetLocalizationText();

  useOnOutsideClick(
    ref,
    () => {
      if (showFilter && !freez && !advancedFilterIsLoading) {
        setShowFilter(false);
      }
    },
    [showFilter, freez, advancedFilterIsLoading]
  );

  const containerWidth = containerRef?.current?.clientWidth;

  return (
    <div className={`advanceFilter-wrapper`} ref={containerRef}>
      <div
        ref={ref}
        className="advanceFilter-container"
        style={{
          width: containerWidth,
          pointerEvents: freez ? "none" : "auto",
        }}
      >
        {advancedFilter?.map((category) => (
          <Section
            key={category.title}
            title={category.title}
            defaultState={false}
            style={{ marginTop: "0px", marginBottom: "0px" }}
            titleStyles={{ fontSize: "18px", paddingTop: "10px" }}
            headerStyle={{
              borderBottom: "0px",
              borderTop: "2px solid var(--primaryLightGray)",
            }}
            defaultItemsStyle={{
              animation:
                "product-configurator-section-fadeIn 0.5s ease-in-out forwards",
            }}
            onHideItems={(dispatch, setItemsStyle) => {
              setItemsStyle({
                animation: "product-configurator-section-fadeOut 0.5s infinite",
              });
              setTimeout(() => {
                setItemsStyle({
                  animation:
                    "product-configurator-section-fadeIn 0.5s ease-in-out forwards",
                });

                dispatch();
              }, 400);
            }}
          >
            {category?.items?.some((i) => i.isSelected) && (
              <span
                style={{
                  fontSize: "12px",
                  color: "var(--primaryGreen)",
                  cursor: "pointer",
                }}
                onClick={() => onClearCategoryFilterHandler(category.title)}
              >
                {getLocalizationText("Global_Button_ClearGroupFilter")} x
              </span>
            )}
            <div
              style={{
                display: "flex",
                flexDirection:
                  category?.displayType === "Image" ? "row" : "column",
                gap: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                flexWrap: category?.displayType === "Image" ? "wrap" : "",
              }}
            >
              {category?.items?.map((item) =>
                category?.displayType === "Image" ? (
                  <AdvancedFilterElementImage
                    key={item.tag}
                    item={item}
                    onClick={() => onFilterChangeHandler(item.tag)}
                  />
                ) : (
                  <AdvancedFilterElement
                    key={item.tag}
                    item={item}
                    onClick={() => onFilterChangeHandler(item.tag)}
                  />
                )
              )}
            </div>
          </Section>
        ))}
      </div>
    </div>
  );
}
