import "./Global.css";
import { useEffect, useState } from "react";
import { useActivePage } from "_store/page/selectors";
import { putPageIndexInStorage } from "_config/session";
import { useOnAppGlobalLoadEffect } from "__utilities/hooks/useOnAppGlobalLoadEffect";
import MainPage from "pages/MainPage";
import InvalidDistributorCodePage from "pages/InvalidDistributorCodePage";

function App() {
  const { currPage, setPage } = useActivePage();

  const [canRender, setCanRender] = useState(false);

  const [showDiscardPopup, setDiscardPopup] = useState(false);
  const [discardPopupDispatch, setDiscardPopupDispatch] = useState(() => {});
  const [discardPopupOnCloseDispatch, setDiscardPopupOnCloseDispatch] =
    useState(() => {});

  const [showInvalidBiocodePopup, setInvalidBiocodePopup] = useState(false);
  const [isInvalidDistributorCode, setIsInvalidDistributorCode] =
    useState(false);

  useOnAppGlobalLoadEffect(
    setCanRender,
    setDiscardPopup,
    setDiscardPopupDispatch,
    setDiscardPopupOnCloseDispatch,
    setInvalidBiocodePopup,
    setIsInvalidDistributorCode
  );

  useEffect(() => {
    putPageIndexInStorage(currPage);
  }, [currPage]);

  return isInvalidDistributorCode ? (
    <InvalidDistributorCodePage />
  ) : (
    <MainPage
      canRender={canRender}
      showDiscardPopup={showDiscardPopup}
      discardPopupDispatch={discardPopupDispatch}
      discardPopupOnCloseDispatch={discardPopupOnCloseDispatch}
      showInvalidBiocodePopup={showInvalidBiocodePopup}
      currPage={currPage}
      setPage={setPage}
      setDiscardPopup={setDiscardPopup}
      setInvalidBiocodePopup={setInvalidBiocodePopup}
    />
  );
}

export default App;
