import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Configuration from "features/product/Configuration";
import { Fragment, useLayoutEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import { energyTypeSelector } from "_store/products/energyTypes/selectors";
import {
  getEnergyTypes,
  selectEnergyType,
} from "_store/products/energyTypes/service";
import { summarySelector, useRefreshSummary } from "_store/summary/selectors";
import useDidMountEffect from "__utilities/hooks/useDidMountEffect";

const EnergyTypePage = () => {
  const getLocalizationText = useGetLocalizationText();
  const { currPage } = useActivePage();
  const refreshSummary = useRefreshSummary();
  const [loaded, setLoaded] = useState(true);

  const [{ isLoading, energyTypes: sections }, dispatch] =
    useRecoilState(energyTypeSelector);

  const summary = useRecoilValue(summarySelector);

  useLayoutEffect(() => {
    refreshSummary(currPage);
    getEnergyTypes(dispatch);
  }, []);

  useDidMountEffect(() => {
    setLoaded(false);
  }, [sections]);

  const isPageLocked =
    summary?.steps?.find((s) => s.number === currPage)?.isLocked !== false
      ? true
      : false;

  const currentlySelectedPriceAmount = sections.find(
    (e) => e.isSelected === true
  )?.price?.amount;

  return (
    <div className="page-container">
      {isLoading && loaded ? (
        <div className="centred-container">
          <LoadingSpinner />
        </div>
      ) : (
        <Fragment>
          <div className="page-header-title">
            {getLocalizationText("Step4_Subtitle")}
          </div>
          {sections &&
            sections.map((element) => {
              return (
                <Configuration
                  type="energy"
                  key={element.id}
                  id={element.id}
                  title={element.title}
                  details={element.details}
                  price={element.price}
                  image={element.image}
                  isSelected={element.isSelected}
                  currentlySelectedPriceAmount={currentlySelectedPriceAmount}
                  userConfirmationRequired={element.userConfirmationRequired}
                  onClickHandler={(id) => {
                    id && selectEnergyType({ id }, dispatch);
                  }}
                  isClickable={!isLoading && !isPageLocked}
                />
              );
            })}
        </Fragment>
      )}
    </div>
  );
};

export default EnergyTypePage;
