import { useGetLocalizationText } from "_store/localization/selectors";
import CustomButton from "components/Buttons/CustomButton";
import Popup from "components/Popup/Popup";

export default function InvalidBiocodePopup({ onClose }) {
  const getLocalizationText = useGetLocalizationText();

  return (
    <Popup
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        alignItems: "self-start",
        justifyContent: "start",
        padding: "10px",
        background: "var(--primarySuperLightGray)",
        overflow: "auto",
        overflowX: "hidden",
        maxHeight: "90%",
      }}
      onClose={onClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px",
        }}
      >
        <div
          style={{
            marginBottom: "40px",
            fontSize: "15px",
            fontWeight: "300",
            "line-height": "30px",
            "letter-spacing": "0.03em",
            textAlign: "center",
          }}
        >
          {getLocalizationText("DeepLink_BioCode_Invalid")}
        </div>
        <CustomButton
          text={getLocalizationText("Global_Button_Cancel")}
          type="previous"
          onClick={onClose}
          style={{ width: "200px" }}
        />
      </div>
    </Popup>
  );
}
