import { atom } from "recoil";
import { chooseRegionPage } from "__utilities/PageStepEnum";

const state = atom({
  key: "store.page.state",
  default: {
    page: chooseRegionPage,
  },
});

export default state;
