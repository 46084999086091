import React, { useEffect, useRef } from "react";
import "./MultiStepProgressBar.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useRecoilValue } from "recoil";
import { summarySelector } from "_store/summary/selectors";
import AlertSign from "components/AlertSign/AlertSign";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { Tooltip } from "@mui/material";

const MultiStepProgressBar = ({ currentStep }) => {
  var stepPercentage = 0;
  
  const getLocalizationText = useGetLocalizationText();
  const { steps } = useRecoilValue(summarySelector);

  const currentStpeRef = useRef(null);
  const isSmallScreen = useIsSmallScreen();

  const selectionSteps = [
    {
      text: getLocalizationText("StepOverview_Step0"),
      accessor: "region",
      index: 0,
    },
    {
      text: getLocalizationText("StepOverview_Step1"),
      accessor: "product",
      index: 1,
    },
    {
      text: getLocalizationText("StepOverview_Step2"),
      accessor: "dimensions",
      index: 2,
    },
    {
      text: getLocalizationText("StepOverview_Step3"),
      accessor: "material",
      index: 3,
    },
    {
      text: getLocalizationText("StepOverview_Step4"),
      accessor: "energyType",
      index: 4,
    },
    {
      text: getLocalizationText("StepOverview_Step5"),
      accessor: "doors",
      index: 5,
    },
    {
      text: getLocalizationText("StepOverview_Step6"),
      accessor: "hinging",
      index: 6,
    },
    {
      text: getLocalizationText("StepOverview_Step7"),
      accessor: "base",
      index: 7,
    },
    {
      text: getLocalizationText("StepOverview_Step8"),
      accessor: "equipment and fittings",
      index: 8,
    },
    {
      text: getLocalizationText("StepOverview_Step9"),
      accessor: "interior",
      index: 9,
    },
    {
      text: getLocalizationText("StepOverview_Step10"),
      accessor: "summary",
      index: 10,
    },
  ];


  if (currentStep <= 10) {
    stepPercentage = (100 / (selectionSteps.length - 1)) * (currentStep);
  } else if (currentStep >= 100) {
    stepPercentage = 100;
    currentStep = 10;
  } else {
    stepPercentage = 0;
  }

  const tooltip = (index, content) => (
    <Tooltip title={steps?.[index]?.infoText?.replaceAll("$", "")}>{content}</Tooltip>
  );

  const content = () => {
    return (
      <div
        className={
          "multi-step-progress-bar-container" +
          (isSmallScreen ? " smallScreen" : "")
        }
      >
        <ProgressBar
          percent={stepPercentage}
          filledBackground={"#81bcb1"}
          unfilledBackground={"var(--secondaryLightGray)"}
          height={"3px"}
        >
          {selectionSteps.map((step) => (
            <Step key={`progress-step-${step.index}`}>
              {({ accomplished, index }) => {
                return (
                  <>
                    {steps?.[index]?.isAvailable ? (
                      <div
                        ref={
                          steps?.[index].number === currentStep
                            ? currentStpeRef
                            : null
                        }
                        className={`indexedStep ${
                          (accomplished ? "accomplished" : "") +
                          (index === currentStep ? "Current" : "")
                        }`}
                      >
                        {index === currentStep && (
                          <div className="indexedStep-content">{index + 1}</div>
                        )}
                        {tooltip(
                          index,
                          <div
                            className={`indexedStep-name accomplished ${
                              index === currentStep ? "current" : ""
                            }`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            {selectionSteps[index].text}
                            {steps?.[index]?.infoText && <AlertSign />}
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        {tooltip(index, 
                          <div
                          className={"indexedStep-name"}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          {selectionSteps[index].text}

                          {steps?.[index]?.infoText && <AlertSign />}
                        </div>
                          )}
                      </>
                    )}
                  </>
                );
              }}
            </Step>
          ))}
        </ProgressBar>
      </div>
    );
  };

  useEffect(() => {
    isSmallScreen &&
      currentStpeRef?.current?.scrollIntoView({
        block: "nearest",
        inline: "center",
      });
  }, [currentStep]); // bar position for small screens

  return (
    <div
      className={isSmallScreen && "multi-step-progress-bar-smallScreen-wrapper"}
    >
      {content()}
    </div>
  );
};

export default MultiStepProgressBar;
