export default function SortOptionsDropdown({
  selectedValue,
  options,
  onChange = () => {},
  styles = {}
}) {
  const handleSelectChange = (event) => {
    const value = event.target.value;
    onChange(value);
  };

  return (
    <select
      value={selectedValue}
      onChange={handleSelectChange}
      style={{
        border: "1px solid var(--primaryGreen)",
        borderRadius: "10px",
        outline: "none",
        padding: "5px",
        ...styles
      }}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.displayText}
        </option>
      ))}
    </select>
  );
}
