import { selector } from "recoil";
import state from "./index";

export const informingTypeSelector = selector({
  key: "store.informingType.selector",
  get: ({ get }) => get(state),
  set: ({ get, set }, newValue) =>
    set(state, {
      ...get(state),
      informingType: newValue,
    }),
});
