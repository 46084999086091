import { getUuid } from "__utilities/functions/Public";
import { deleteAxiosInstanceHeader, updateAxiosInstanceHeader } from "./client";

const sessionIdKey = "BioLine-Session-Id";
const configurationIdKey = "BioLine-Configuration-Id";
const regionKey = "BioLine-Region";
const languageKey = "BioLine-Language";
const pageKey = "BioLine-Page";
const firstStartUpHappenedKey = "BioLine-FirstStartUpHappened";
const readOnlyModeKey = "BioLine-ReadOnlyMode";
const acquireQuotationRequestMessageKey =
  "BioLine-AcquireQuotationRequestMessage";
const readOnlyModeOnKey = "ON";
const readOnlyModeOffKey = "OFF";
const userSelectionKey = "BioLine-User-Selection";
const distributorHeaderKey = "BioLine-Distributor-Code"

export const setSessionId = (sessionId) => {
  sessionId
    ? sessionStorage.setItem(sessionIdKey, sessionId)
    : sessionStorage.removeItem(sessionIdKey);
};

export const setNewSessionId = (id) => {
  if (id) {
    setSessionId(id);
  } else {
    setSessionId(getUuid());
  }
};

export const getSessionId = () => {
  let sessionId = sessionStorage.getItem(sessionIdKey);
  if (!sessionId) {
    if (!sessionId) {
      sessionId = getUuid();
      setSessionId(sessionId);
    }
  }
  return sessionId;
};

export const setConfigurationId = (id) => {
  sessionStorage.setItem(configurationIdKey, id);
};

export const getConfigurationId = () => {
  return sessionStorage.getItem(configurationIdKey);
};

export const deleteConfigurationId = () => {
  sessionStorage.removeItem(configurationIdKey);
};

export const setReadOnlyMode = (activate) => {
  if (activate) {
    sessionStorage.setItem(readOnlyModeKey, readOnlyModeOnKey);
  } else {
    sessionStorage.setItem(readOnlyModeKey, readOnlyModeOffKey);
  }
};

export const getReadOnlyModeStatus = () => {
  if (sessionStorage.getItem(readOnlyModeKey) === readOnlyModeOnKey) {
    return true;
  } else if (sessionStorage.getItem(readOnlyModeKey) === readOnlyModeOffKey) {
    return false;
  }
  return false;
};

export const urlSessionIdCheck = (
  validation,
  readOnlyDispatch,
  acquireQuotationDispatch
) => {
  if (validation.isValid) {
    if (validation.acquireQuotation) {
      // acquireQuotation redirect
      setNewSessionId(validation.sessionId);
      acquireQuotationDispatch();
    } else {
      setNewSessionId(validation.sessionId);
      setReadOnlyMode(true);
      readOnlyDispatch();
    }
  } else {
    console.log("URL not valid!!!");
  }
};

export const urlSessionIdIsValid = () => {
  const params = new URLSearchParams(window.location.search);
  var pathSessionId = ""
  let acquireQuotation = false;

  if (params.has("id")) {
    pathSessionId = params.get("id")
  }

  let validPath = pathSessionId && pathSessionId !== "" ? true : false;
  return {
    isValid: validPath,
    sessionId: pathSessionId?.toLocaleLowerCase(),
    acquireQuotation: acquireQuotation,
  };
};

export const getBioCodeFromURL = () => {
  const params = new URLSearchParams(window.location.search);
  let biocode = null

  if (params.has("biocode")) {
    biocode = params.get("biocode")
    return biocode
  }

  return biocode
}

export const getDistributorFromURL = () => {
  const params = new URLSearchParams(window.location.search);
  let distributor = null

  if (params.has("distributor")) {
    distributor = params.get("distributor")
    return distributor
  }

  return distributor
}

export const setDistributorHeader = (distributor) => {
  updateAxiosInstanceHeader(distributorHeaderKey, distributor)
}

export const deleteDistributorHeader = () => {
  deleteAxiosInstanceHeader(distributorHeaderKey)
}

export const setRegion = (region) => {
  region
    ? localStorage.setItem(regionKey, region)
    : localStorage.removeItem(regionKey);
};

export const getRegion = () => {
  let region = localStorage.getItem(regionKey);
  // if (!region) {
    
  //   region = "DK";
  //   setRegion(region);
  // }
  return region;
};

export const setLanguageHeader = (language) => {
  updateAxiosInstanceHeader(languageKey, language)
}

export const addHeaders = (config) => {
  const sessionId = getSessionId();
  const region = getRegion();
  const language = getLanguageFromURL()
  const configurationId = getConfigurationId();

  config.headers[sessionIdKey] = sessionId;
  config.headers[regionKey] = region;
  config.headers[languageKey] = language;
  config.headers[configurationIdKey] = configurationId;

  return config;
};

export const putPageIndexInStorage = (pageIndex) => {
  sessionStorage.setItem(pageKey, pageIndex);
};

export const getPageIndexFromStorage = () => {
  return sessionStorage.getItem(pageKey);
};

export const isFirstStartUpHappened = () => {
  if (!sessionStorage.getItem(firstStartUpHappenedKey)) {
    sessionStorage.setItem(firstStartUpHappenedKey, true);
    return true;
  }
  return false;
};

export const removeIsFirstStartUpHappened = () => {
  sessionStorage.removeItem(firstStartUpHappenedKey);
};

export const getAcquireQuotationRequestMessage = () => {
  return sessionStorage.getItem(acquireQuotationRequestMessageKey);
};
export const setAcquireQuotationRequestMessage = (value) => {
  sessionStorage.setItem(acquireQuotationRequestMessageKey, value);
};

export const getUserSelection = () => {
  return sessionStorage.getItem(userSelectionKey);
};
export const setUserSelection = (value) => {
  sessionStorage.setItem(userSelectionKey, value);
};
export const getLanguageFromURL = () => {
  let language = "en-en"
  
  if(window.location.href.includes("/fr-fr")) {
    language = "fr-fr"
  } else if(window.location.href.includes("/de-de"))  {
    language = "de-de"
  } else if(window.location.href.includes("/nl-nl"))  {
    language = "nl-nl"
  } 
  
  return language
}

export const getRegionFromURL = () => {
  let region = "DK"
  
  if(window.location.href.includes("/fr-fr")) {
    region = "FR"
  } else if(window.location.href.includes("/de-de"))  {
    region = "DE"
  } else if(window.location.href.includes("/nl-nl"))  {
    region = "NL"
  } 
  
  return region
}

