import "./CustomRadioButton.css";

const CustomRadioButton = ({ state, innerCircleStyle, outerCircleStyle }) => {
  return (
    <div className="custom-radio-button-container" >
      <div
        className={
          state ? "custom-radio-button active" : "custom-radio-button "
        }
        style={{...outerCircleStyle}}
      >
        {state && <div className="custom-radio-button-centre-circle" style={{...innerCircleStyle}}/>}
      </div>
    </div>
  );
};

export default CustomRadioButton;
