import { atom } from "recoil";

const state = atom({
  key: "store.summary.state",
  default: {
    summary: {
      isLoading: false,
      steps: [],
      bioCode: [],
      totalPrice: {},
      cabinet:{}
    },
  },
});

export default state;
