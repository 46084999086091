import { useState } from "react";
import { useClosedSections } from "_store/page/sectionsState/selectors";
import { useActivePage } from "_store/page/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { optionsPage } from "__utilities/PageStepEnum";
import "./Section.css";

const Section = ({
  defaultState = true,
  headerDisabled,
  title,
  description,
  interactable,
  type,
  children,
  state,
  disabledBorder,
  style,
  titleStyles = {},
  headerStyle = {},
  defaultItemsStyle = {}, 
  onHideItems = (dispatch) => { if(dispatch) dispatch() }
}) => {
  interactable = interactable === false ? false : true;

  const [showSectionState, setShowSectionState] = useState(
    defaultState /*state !== null && state !== undefined ? state : defaultState*/
  );

  const [itemsStyle, setItemsStyle] = useState(defaultItemsStyle)

  const { setClosed, getClosed } = useClosedSections();
  const { currPage } = useActivePage();

  const isSmallScreen = useIsSmallScreen();

  let showState = showSectionState;
  if (currPage >= optionsPage) {
    if (state === false) {
      showState = state;
    } else {
      showState =
        getClosed(title) !== null ? getClosed(title) : showSectionState;
    }
  }

  const handleOnClick = (state) => {
    if(state) {
      setShowSectionState(state)
    } else {
      onHideItems(() => { setShowSectionState(state) }, setItemsStyle)
    }
  }

  return (
    <div
      className={"section-container" + (state === false ? " disabled" : "")}
      style={{ ...style }}
    >
      {!headerDisabled && (
        <div
          onClick={
            interactable
              ? () => {
                  if (currPage >= 5) {
                    setClosed(title);
                  }
                  handleOnClick(!showState);
                }
              : () => {}
          }
        >
          <div
            className="section-header "
            style={{ border: disabledBorder ? "none" : "", ...headerStyle }}
          >
            <div className="section-text-container">
              <div
                className={
                  "section-title" + (isSmallScreen ? " smallScreen" : "")
                }
                style={{ ...titleStyles }}
              >
                {title}
              </div>
              <div className="section-description">{description}</div>
            </div>

            {interactable && (
              <div className="section-icon">
                <img
                  alt=""
                  className={`section-arrow-icon-${showState ? "up" : "down"}`}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {(showState || (!showState && headerDisabled)) && (
        <div className={`section-items-flex-${type}`} style={{...itemsStyle}}>{children}</div>
      )}
    </div>
  );
};

export default Section;
