import state from "./index";
import { selector } from "recoil";

export const filterLimitSelector = selector({
  key: "store.filterLimit.selector",
  get: ({ get }) => get(state).filter,
  set: ({ get, set }, newValue) => {
    set(state, {
      ...get(state),
      filter: newValue,
    });
  },
});
