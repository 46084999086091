import { useGetLocalizationText } from "_store/localization/selectors";
import React from "react";

export default function DeletePopupOpener({ onClick }) {
  const getLocalizationText = useGetLocalizationText();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "var(--primarySuperLightGray)",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <div
        onClick={onClick}
        style={{
          color: "var(--primaryGreen)",
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: "16px",
          cursor: "pointer"
        }}
      >
        {getLocalizationText("Global_Button_BackToEstimateOverview")}
      </div>
    </div>
  );
}
