import { atom } from "recoil";

const state = atom({
  key: "store.page.sections.state",
  default: {
    state: [],
  },
});

export default state;
