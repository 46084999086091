import "./RemoveButton.css";

const RemoveButton = ({ onClick }) => {
  return (
    <div className="remove-button-container" onClick={() => onClick()}>
      <div className="remove-button-cross"></div>
    </div>
  );
};

export default RemoveButton;
