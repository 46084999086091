import CountryDropDown from "components/DropDown/CountryDropDown";
import InputField from "components/InputField/InputField";
import LoadingSpinnerCircle from "components/LoadingSpinner/LoadingSpinnerCircle";
import PhoneInput from "components/PhoneInput";
import Popup from "components/Popup/Popup";
import { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { countriesSelector, useGetCountries } from "_store/countries/selectors";
import { informingTypeSelector } from "_store/informingType/selectors";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import {
  userDataCitySelector,
  userDataCountrySelector,
  userDataEmailSelector,
  userDataFirstNameSelector,
  userDataLastNameSelector,
  userDataOrganizationSelector,
  userDataPhoneSelector,
  userDataSelector,
  userDataStreet1Selector,
  userDataStreet2Selector,
  userDataStreet3Selector,
  userDataZipSelector,
  useRefreshUserDataState,
  userReferenceSelector,
} from "_store/userData/selectors";
import { userDataSendingStateSelector } from "_store/userData/sendingState/selectors";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { informingTypePage } from "__utilities/PageStepEnum";
import "./CollectUserDataPage.css";

export default function CollectUserDataPage() {
  const { informingType } = useRecoilValue(informingTypeSelector);
  const { setPage } = useActivePage();
  const getLocalizationText = useGetLocalizationText();
  const countries = useRecoilValue(countriesSelector);
  const [selectedCountry, setSelectedCountry] = useState();

  const setFirstName = useSetRecoilState(userDataFirstNameSelector);
  const setLastName = useSetRecoilState(userDataLastNameSelector);
  const setOrganization = useSetRecoilState(userDataOrganizationSelector);
  const setEmail = useSetRecoilState(userDataEmailSelector);
  const setCountry = useSetRecoilState(userDataCountrySelector);
  const setPhone = useSetRecoilState(userDataPhoneSelector);

  const setStreet1 = useSetRecoilState(userDataStreet1Selector);
  const setStreet2 = useSetRecoilState(userDataStreet2Selector);
  const setStreet3 = useSetRecoilState(userDataStreet3Selector);

  const setZip = useSetRecoilState(userDataZipSelector);
  const setCity = useSetRecoilState(userDataCitySelector);

  const setReference = useSetRecoilState(userReferenceSelector);

  const refresh = useRefreshUserDataState();

  const { isSending } = useRecoilValue(userDataSendingStateSelector);

  !informingType && setPage(informingTypePage);

  const getCountries = useGetCountries();

  const state = useRecoilValue(userDataSelector);

  const onChangeHandler = (event, dispatch) => {
    dispatch(event.target.value);
  };

  const [selectedPhone, setSelectedPhone] = useState("")

  const isSmallScreen = useIsSmallScreen()

  const phoneCode = () => 
    "+" +
    (countries?.list?.filter((country) => country.name === selectedCountry)?.[0]
      ?.phoneCode ?? "");

  useEffect(() => {
    refresh();
    getCountries((list) => {
      const defaultCountry = list?.filter(
        (country) => country.isDefault === true
      )?.[0]?.name;
      setCountry(defaultCountry);
      setSelectedCountry(defaultCountry);
    });
  }, []);

  useEffect(() => {
    if (selectedPhone) {
      if (selectedCountry === "Other") {
        setPhone(selectedPhone)
      } else {
        setPhone(phoneCode() + selectedPhone)
      }
    } else {
      setPhone("")
    }
  }, [selectedCountry, selectedPhone]);

  return (
    <div className={"collect-user-data-page-container" + (isSmallScreen ? " smallScreen" : "")}>
      {isSending && (
        <Popup>
          {getLocalizationText("CollectUserData_SendingPopup_Text")}
          <LoadingSpinnerCircle />
        </Popup>
      )}
      <div className="collect-user-data-page-header">
        {informingType === "createConfigurationID" &&
          getLocalizationText("CollectUserData_CreateConfigID_Title")}
        {informingType === "quotation" &&
          getLocalizationText("CollectUserData_SendQuotation_Title")}
        {informingType === "contactMe" &&
          getLocalizationText("ColectUserData_ContactMe_Title")}
      </div>
      <div className="collect-user-data-page-input-container-wrapper">
        <div className={"collect-user-data-page-input-container" + (isSmallScreen ? " smallScreen" : "")}>
          <InputField
            isInvalid={state.firstName.valid === false}
            invalidText={getLocalizationText(
              "InputFieldValidation_Mandatory_Text"
            )}
            label={
              getLocalizationText("CollectUserData_FirstName_Label") + " *"
            }
            onChange={(event) => onChangeHandler(event, setFirstName)}
          />
          <InputField
            isInvalid={state.lastName.valid === false}
            invalidText={getLocalizationText(
              "InputFieldValidation_Mandatory_Text"
            )}
            label={getLocalizationText("CollectUserData_LastName_Label") + " *"}
            onChange={(event) => onChangeHandler(event, setLastName)}
          />
          <InputField
            isInvalid={state.organization.valid === false}
            invalidText={getLocalizationText(
              "InputFieldValidation_Mandatory_Text"
            )}
            label={
              getLocalizationText("CollectUserData_Organisation_Label") + " *"
            }
            onChange={(event) => onChangeHandler(event, setOrganization)}
          />
          {informingType === "quotation" && (
            <>
              <InputField
                isInvalid={state.street1.valid === false}
                invalidText={getLocalizationText(
                  "InputFieldValidation_Mandatory_Text"
                )}
                label={
                  getLocalizationText("CollectUserData_Street1_Label") + " *"
                }
                onChange={(event) => onChangeHandler(event, setStreet1)}
              />
              <InputField
                isInvalid={state.street2.valid === false}
                label={getLocalizationText("CollectUserData_Street2_Label")}
                onChange={(event) => onChangeHandler(event, setStreet2)}
              />
              <InputField
                isInvalid={state.street3.valid === false}
                label={getLocalizationText("CollectUserData_Street3_Label")}
                onChange={(event) => onChangeHandler(event, setStreet3)}
              />
              <InputField
                isInvalid={state.zip.valid === false}
                invalidText={getLocalizationText(
                  "InputFieldValidation_Mandatory_Text"
                )}
                label={getLocalizationText("CollectUserData_Zip_Label") + " *"}
                onChange={(event) => onChangeHandler(event, setZip)}
              />
              <InputField
                isInvalid={state.city.valid === false}
                invalidText={getLocalizationText(
                  "InputFieldValidation_Mandatory_Text"
                )}
                label={getLocalizationText("CollectUserData_City_Label") + " *"}
                onChange={(event) => onChangeHandler(event, setCity)}
              />
            </>
          )}
          <CountryDropDown
            isInvalid={state.country.valid === false}
            label={getLocalizationText("CollectUserData_Country_Label") + " *"}
            countries={countries.list ?? []}
            setSelectedCountry={(selected) => {
              setSelectedCountry(selected);
              if (selected !== "Other") {
                setCountry(selected);
              }
            }}
            showCustomInput={selectedCountry === "Other"}
            onCustomNameChange={(event) => onChangeHandler(event, setCountry)}
          />
          <InputField
            isInvalid={state.email.valid === false}
            invalidText={
              state.email.value.length > 0
                ? getLocalizationText(
                    "InputFieldValidation_BadEmailFormat_Text"
                  )
                : getLocalizationText("InputFieldValidation_Mandatory_Text")
            }
            label={getLocalizationText("CollectUserData_Email_Label") + " *"}
            onChange={(event) => onChangeHandler(event, setEmail)}
          />
          <PhoneInput
            isInvalid={state.phone.valid === false}
            invalidText={getLocalizationText(
              "InputFieldValidation_Mandatory_Text"
            )}
            label={
              getLocalizationText("CollectUserData_Phone_Label") +
              (informingType === "contactMe" ? " *" : "")
            }
            phoneCode={phoneCode()}
            customNumber={selectedCountry === "Other"}
            onChange={(phone) => setSelectedPhone(phone)}
          />
          <InputField
            isInvalid={state.reference.valid === false}
            invalidText={getLocalizationText(
              "InputFieldValidation_Mandatory_Text"
            )}
            label={getLocalizationText("CollectUserData_Reference_Label")}
            onChange={(event) => onChangeHandler(event, setReference)}
          />
        </div>
      </div>
    </div>
  );
}
