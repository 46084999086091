import { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { summarySelector } from "_store/summary/selectors";
import "./DimensionsIndicator.css";

const DimensionsIndicator = ({style}) => {
  const summary = useRecoilValue(summarySelector);

  return (
    <Fragment>
      {summary?.cabinet?.dimensionLabel ? (
        <div className="dimensions-indicator-container" style={{...style}}>
          <div className="dimensions-indicator-label">
            {summary?.cabinet?.dimensionLabel}
          </div>
          <div className="dimensions-indicator-value">
            {summary?.cabinet?.dimensionValue}
          </div>
        </div>
      ) : (
        <Fragment></Fragment>
      )}
    </Fragment>
  );
};

export default DimensionsIndicator;
