import { estimatePage } from "__utilities/PageStepEnum";
import { getConfigurationId } from "_config/session";
import { deleteConfiguration } from "_store/configurations/service";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useActivePage } from "_store/page/selectors";
import CustomButton from "components/Buttons/CustomButton";
import Popup from "components/Popup/Popup";
import React from "react";

export default function DeletePopup({ onClose }) {
  const getLocalizationText = useGetLocalizationText();
  const { setPage } = useActivePage();

  const onDelete = () => {
    const id = getConfigurationId();

    deleteConfiguration(id, (success) => {
      if (success) {
        setPage(estimatePage);
        onClose();
      } else {
        console.log("Error on delete!!!");
      }
    });
  };

  return (
    <Popup
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "0px",
        alignItems: "self-start",
        justifyContent: "start",
        padding: "10px",
        background: "var(--primarySuperLightGray)",
        overflow: "auto",
        overflowX: "hidden",
        maxHeight: "90%",
      }}
      onClose={onClose}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px",
          maxWidth: "600px"
        }}
      >
        <div style={{ marginBottom: "25px", fontSize: "24px", fontWeight: "400", "line-height": "30px", "letter-spacing": "0em", textAlign: "center" }}>
          {getLocalizationText("EstimateOverview_UserConfirmation_Title")}
        </div>
        <div style={{ marginBottom: "40px", fontSize: "15px", fontWeight: "300", "line-height": "30px", "letter-spacing": "0.03em", "text-wrap": "wrap", textAlign: "center" }}>
          {getLocalizationText("EstimateOverview_UserConfirmation_Text")}
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <CustomButton
            text={getLocalizationText("Global_Button_Cancel")}
            type="previous"
            onClick={onClose}
            style={{ width: "200px" }}
          />
          <CustomButton
            text={getLocalizationText("Global_Button_Confirm")}
            type="next"
            onClick={onDelete}
            style={{ width: "200px" }}
          />
        </div>
      </div>
    </Popup>
  );
}
