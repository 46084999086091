import { atom } from "recoil";

const state = atom({
  key: "store.products.cabinets.state",
  default: {
    isLoading: false,
    cabinets: [],
    selectedSortOption: null
  },
});

export default state;