import { atom } from "recoil";

const state = atom({
  key: "store.products.materials.state",
  default: {
    isLoading: false,
    materials: []
  },
});

export default state;