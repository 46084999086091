import { useEffect, Fragment, useState, useRef } from "react";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import Section from "components/Section";
import { useGetLocalizationText } from "_store/localization/selectors";
import { useRefreshSummary } from "_store/summary/selectors";
import {
  deselectOption,
  getOptions,
  selectManyOption,
  selectOption,
} from "_store/products/options/service";
import Option from "features/product/Option";
import { optionsSelector } from "_store/products/options/selectors";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { descriptionSelector } from "_store/selected/Description/selectors";
import { compartmentSelector } from "_store/selected/compartment/selectors";
import { useActivePage } from "_store/page/selectors";
import OptionSummary from "features/selection/OptionSummary/OptionSummary";
import useComponentSize from "@rehooks/component-size";
import { useIsSmallScreen } from "__utilities/hooks/useIsSmallScreen";
import { useIsMediumScreen } from "__utilities/hooks/useIsMediumScreen";

const filter = (elementFilteredType, filteringType, alwaysPassingType) => {
  if (
    elementFilteredType === filteringType ||
    elementFilteredType === alwaysPassingType
  ) {
    return true;
  }
  return false;
};

const OptionsPage = () => {
  const getLocalizationText = useGetLocalizationText();
  const { currPage } = useActivePage();
  const refreshSummary = useRefreshSummary();
  // const [loaded, setLoaded] = useState(true);

  let ref = useRef(null);
  const containerSize = useComponentSize(ref);

  const [{ isLoading, options: sections }, dispatchSetOptions] =
    useRecoilState(optionsSelector);

  const dispatchSetDescription = useSetRecoilState(descriptionSelector);

  const [showSpinner, setShowSpinner] = useState(true);

  const didMount = useRef(false);

  const selectedCompartment = useRecoilValue(compartmentSelector);

  const allOptions = sections.flatMap((section) => section.options);
  const currentlySelectedPriceAmount = allOptions.find(
    (e) => e.isSelected === true
  )?.price?.amount;

  const isSmallScreen = useIsSmallScreen()
  const isMediumScreen = useIsMediumScreen()

  useEffect(() => {
    refreshSummary(currPage);
    getOptions(dispatchSetOptions, currPage);
  }, []);

  useEffect(() => {
    if (didMount.current) {
      if (!isLoading) {
        setShowSpinner(false);
        return;
      }
      const timer = setTimeout(() => setShowSpinner(isLoading), 500);

      return () => clearTimeout(timer);
    } else didMount.current = true;
  }, [isLoading]);

  return (
    <div className={"page-container" + (isLoading ? " lock" : "")} ref={ref}>
      {showSpinner ? (
        <div className="centred-container">
          <LoadingSpinner />
        </div>
      ) : (
        <Fragment>
          <div className="section-container-wrapper">
            {sections.map((section) => {
              return (
                <Section
                  interactable={false}
                  disabledBorder={sections.length <= 1 ? true : false}
                  key={`${section.title}-${section.description}`}
                  title={section.title}
                  description={section.description}
                  type="column"
                  state={
                    null
                    // section?.options?.filter(
                    //   (e) => e.isDisabled !== true && e.isLocked !== true
                    // ).length === 0
                    //   ? false
                    //   : null
                  }
                >
                  {section.options.map((option) => {
                    return (
                      filter(
                        option.compartment,
                        selectedCompartment,
                        "Global"
                      ) && (
                        <Option
                          key={`${option.id}-${option.title}-${option.compartment}`}
                          id={option.id}
                          compartment={option.compartment}
                          title={option.title}
                          price={option.price}
                          image={option.imageUrl}
                          details={option.details}
                          isAvailable={option.isAvailable}
                          isSelected={option.isSelected}
                          isDisabled={option.isDisabled}
                          disableReason={option.disableReason}
                          isLocked={option.isLocked}
                          canDelete={option.canDelete}
                          quantity={option.quantity}
                          minValue={option.minValue}
                          maxValue={option.maxValue}
                          size={option.size}
                          optionType={option.type}
                          userConfirmationOnDeleteRequired={option.userConfirmationOnDeleteRequired}
                          userConfirmationOnDeleteText={option.userConfirmationOnDeleteText}
                          userConfirmationOnSelectRequired={option.userConfirmationOnSelectRequired}
                          userConfirmationOnSelectText={option.userConfirmationOnSelectText}
                          isMaxAmountReached={option.isMaxAmountReached}
                          maxAmountReachedText={option.maxAmountReachedText}
                          currentlySelectedPriceAmount={
                            currentlySelectedPriceAmount
                          }
                          onClickHandler={(quantity, type) => {
                            if (type === "Group") {
                              option.isSelected
                                ? deselectOption(
                                    option,
                                    dispatchSetOptions,
                                    dispatchSetDescription,
                                    currPage
                                  )
                                : selectOption(
                                    option,
                                    dispatchSetOptions,
                                    dispatchSetDescription,
                                    currPage
                                  );
                              return;
                            }

                            quantity
                              ? selectManyOption(
                                  { ...option, quantity },
                                  dispatchSetOptions,
                                  dispatchSetDescription,
                                  currPage
                                )
                              : option.isSelected
                              ? deselectOption(
                                  option,
                                  dispatchSetOptions,
                                  dispatchSetDescription,
                                  currPage
                                )
                              : selectOption(
                                  option,
                                  dispatchSetOptions,
                                  dispatchSetDescription,
                                  currPage
                                );
                          }}
                        ></Option>
                      )
                    );
                  })}
                </Section>
              );
            })}
          </div>
        </Fragment>
      )}
      {!isSmallScreen && !isMediumScreen && <OptionSummary width={containerSize.width} />}
    </div>
  );
};

export default OptionsPage;
