import ApiClient from "_config/client";
import { getModelDescriptionRequest } from "_store/selected/Description/service";
import types from "_store/types";

const base = "/option";

export const getOptionsRequestBuilder = (stepNumber) => ApiClient.get(`${base}/${stepNumber}`);
export const selectOptionRequestBuilder = (compartment, id) =>
  ApiClient.post(`${base}/yesno/${compartment}/${id}/select`);
export const selectManyOptionRequestBuilder = (compartment, id, quantity) =>
  ApiClient.post(
    `${base}/numeric/${compartment}/${id}/setQuantity?quantity=${quantity}`
  );
export const deselectOptionRequestBuilder = (compartment, id) =>
  ApiClient.post(`${base}/yesno/${compartment}/${id}/deselect`);

// the right way
// export const selectOptionRequestBuilder = (compartment, id) =>
// ApiClient.post(`${base}/${compartment}/${id}/${1}`)
// export const selectManyOptionRequestBuilder = (compartment, id, quantity) =>
// ApiClient.post(`${base}/${compartment}/${id}/${quantity}`)
// export const deselectOptionRequestBuilder = (compartment, id) =>
// ApiClient.post(`${base}/${compartment}/${id}/${0}`)

export const getOptions = (dispatch, stepNumber) => {
  dispatch({ type: types.started });

  getOptionsRequestBuilder(stepNumber)
    .then(({ data }) => dispatch({ type: types.success, payload: data }))
    .catch((error) => dispatch({ type: types.error }));
};

export const getModelDescription = (dispatch) => {
  getModelDescriptionRequest().then((data) => {
    dispatch({
      type: "model",
      isLoading: false,
      description: data.data,
    });
  });
};

const dispatchMutatingAction = (
  request,
  dispatchSetOptions,
  dispatchSetDescription,
  stepNumber
) => {
  dispatchSetOptions({ type: types.started });

  request
    .then(() => {
      getOptions(dispatchSetOptions, stepNumber);
      getModelDescription(dispatchSetDescription);
    })
    .catch((error) => dispatchSetOptions({ type: types.error }));
};

export const selectOption = (
  { compartment, id },
  dispatchSetOptions,
  dispatchSetDescription,
  stepNumber
) =>
  dispatchMutatingAction(
    selectOptionRequestBuilder(compartment, id),
    dispatchSetOptions,
    dispatchSetDescription,
    stepNumber
  );

export const selectManyOption = (
  { compartment, id, quantity },
  dispatchSetOptions,
  dispatchSetDescription,
  stepNumber
) =>
  dispatchMutatingAction(
    selectManyOptionRequestBuilder(compartment, id, quantity),
    dispatchSetOptions,
    dispatchSetDescription,
    stepNumber
  );

export const deselectOption = (
  { compartment, id },
  dispatchSetOptions,
  dispatchSetDescription,
  stepNumber
) =>
  dispatchMutatingAction(
    deselectOptionRequestBuilder(compartment, id),
    dispatchSetOptions,
    dispatchSetDescription,
    stepNumber
  );
